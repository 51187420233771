import "./../../../assets/scss/CreateForms.scss";

import { Button, Card, Divider, Form, Input, Space, Switch, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Content } from "antd/es/layout/layout";
import LoadingSkeleton from "../../Layout/LoadingSkeleton";
import MotionBox from "../../Layout/Animated/MotionBox";
import { SaveOutlined } from '@ant-design/icons';
import { SecureWithLogin } from "../../../utils/secure";
import { callBackEnd } from "../../../utils/backEndUtils";
import { useForm } from "antd/es/form/Form";

export default function FormNamespace() {
    const [form] = useForm();
    const navigate = useNavigate();
    const { namespaceId } = useParams();
    const formMode: string = namespaceId ? 'edit' : 'create';
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const [namespaceStaticTypes, setNamespaceStaticTypes] = useState<string[]>([]);
    const [modulesStatic, setModulesStatic] = useState<StaticModuleAccess[]>([]);

    const formData: NamespaceFormDataType = {
        name: '',
        label: '',
        emailDomain: '',
        type: null,
        partnerId: null,
        options: {}
    }
    //namespace types static contoller
    useEffect(() => {
        async function getNamespaceStaticTypes() {
            try {
                const response: any = await callBackEnd('portal', 'GET', `static/namespace_types`);
                if (Array.isArray(response)) {
                    setNamespaceStaticTypes(response);
                }
            }
            catch (error: any) {
                console.log(error);
            }

        }
        async function getModules() {
            try {
                const response: any = await callBackEnd('portal', 'GET', `static/modules`);
                setModulesStatic(response);
            }
            catch (error: any) {
                console.log(error);
            }
        }

        getNamespaceStaticTypes().catch((e: any) => console.log(e));
        getModules().catch((e: any) => console.log(e));
    }, []);
    //Get the namespace data for edit mode
    useEffect(() => {
        async function getNamespace() {
            try {
                setIsProcessing(true);
                const response: any = await callBackEnd('portal', 'GET', `namespaces/${namespaceId}`);
                form.setFieldsValue({
                    label: response.label,
                    name: response.name,
                    emailDomain: response.emailDomain,
                    type: response.type,
                    idpEnabled: response.options.idpEnabled,
                    onPremiseEnabled: response.options.onPremiseEnabled
                });
                form.setFieldsValue({
                    modules: response.options.moduleAccess
                });

            } catch (error: any) {
                console.log(error);
            }
            finally {
                setIsProcessing(false);
            }
        }

        if (formMode === 'edit' && namespaceId && modulesStatic.length > 0) {

            getNamespace().catch((e: any) => console.log(e));
        }
    }, [namespaceId, formMode, form, modulesStatic]);



    const handleForm = (values: any) => {

        async function saveNamespace(data: NamespaceFormDataType) {
            try {
                setIsProcessing(true);
                if (formMode === 'edit' && namespaceId) {
                    //edit
                    await callBackEnd('portal', 'PUT', `namespaces/${namespaceId}`, JSON.stringify(data));
                    navigate(`/namespaces/${namespaceId}`);
                }
                else {
                    //create
                    await callBackEnd('portal', 'POST', `namespaces`, JSON.stringify(data));
                    navigate(`/namespaces/`);
                }



            } catch (error: any) {
                console.log(error);
                notification.error({ message: 'Error while creating your namespace', duration: 0 });
            }
            finally {
                setIsProcessing(false);
            }
        }


        formData.label = values.label;
        formData.name = values.name;
        formData.emailDomain = values.emailDomain;

        formData.options.idpEnabled = values.idpEnabled;
        formData.options.onPremiseEnabled = values.onPremiseEnabled;

        //format to remove undefined values and set them to false
        for (const key in values.modules) {
            if (values.modules[key] === undefined) {
                values.modules[key] = false;
            }
        }
        formData.options.moduleAccess = values.modules;

        delete formData.type;

        saveNamespace(formData).catch((e: any) => console.log(e));
        notification.success({ message: 'Namespace saved!' });

    }

    return (
        <SecureWithLogin callbackPath="/namespaces" role={['crm_namespaces']} namespaceRequired={true}>
            <>
                {namespaceStaticTypes.length > 0 && modulesStatic.length > 0 ?
                    <MotionBox>
                        <Content>
                            <Card
                                title={formMode === 'edit' ? 'Edit namespace' : 'Create new namespace'}
                            >
                                <Form
                                    labelWrap
                                    form={form}
                                    onFinish={handleForm}
                                    labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 21 }}
                                >
                                    <Form.Item
                                        label='Label'
                                        name='label'
                                        rules={[{ required: true, message: 'Please set your namespace label' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label='Name'
                                        name='name'
                                        rules={[{ required: true, message: 'Please set your namespace name' }]}
                                    >
                                        <Input disabled={formMode === 'edit'} />
                                    </Form.Item>
                                    <Form.Item
                                        label='Email Domain'
                                        name='emailDomain'
                                        rules={[{ required: true, message: 'Please set your email domain' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label='Type'
                                        name='type'
                                        tooltip='Please use the Upgrade button on the Namespace page to change the type'
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                    <Form.Item
                                        label='IDP Enabled'
                                        name='idpEnabled'
                                    >
                                        <Switch />
                                    </Form.Item>
                                    {/* <Form.Item
                                        label='Management'
                                        name='management'
                                        rules={[{ required: true, message: 'Please set Management enabled/disabled' }]}
                                    >
                                        <Switch />
                                    </Form.Item> */}
                                    <Form.Item
                                        label='On Premise'
                                        name='onPremiseEnabled'
                                        rules={[{ required: true, message: 'Please set On Premise enabled/disabled' }]}
                                    >
                                        <Switch />
                                    </Form.Item>
                                    <Typography.Text type='secondary'>
                                        Module access
                                    </Typography.Text>
                                    <Divider />
                                    {modulesStatic && modulesStatic.map((module: StaticModuleAccess) => {

                                        //Handles default if mode is not edit
                                        const switchProps = (formMode !== 'edit') ? { "defaultChecked": module.isDefaultEnabled } : {};
                                        return (
                                            <Form.Item
                                                key={module.name}
                                                label={module.name}
                                                name={['modules', module.name]}
                                            >
                                                <Switch
                                                    {...switchProps}
                                                />
                                            </Form.Item>
                                        )
                                    })}

                                    <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                                        <Space size="middle">
                                            <Button type="text" onClick={() => navigate(`/namespaces/${namespaceId}`)}>
                                                Cancel
                                            </Button>
                                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={isProcessing}>
                                                Save
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Content>
                    </MotionBox>
                    :
                    <LoadingSkeleton />
                }
            </>
        </SecureWithLogin>
    )
}
