import { Alert, Button, Flex, List, Typography } from 'antd';

import Card from 'antd/es/card/Card';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import { ShowIfAccess } from '../../utils/secure';
import dayjs from 'dayjs';
import { removeMarkdownFromString } from '../../utils/stringUtils';
import useNews from '../../hooks/useNews';

export const LatestNewsWidget = () => {
    const { news, loading, error } = useNews()
    if (loading) {
        return (
            <Loader />
        )
    }

    if (error) {
        return (
            <Alert
                message="Error"
                description={error.toString()}
                type="error"
                showIcon
            />
        )
    }
    return (
        <>
            {news?.length > 0 &&
                <Card
                    title="Latest News"
                    extra={<Link to='/news'><Button>Read all</Button></Link>}
                    className="card news-widget-card"
                    bordered={false}
                >
                    <Flex vertical gap={"middle"}>
                        <ShowIfAccess authenticated={true} role={['crm_faq_writer']} namespaceRequired={true}>
                            <Alert message="You have are viewing this page as a writer" type="success" showIcon />
                        </ShowIfAccess>

                        {error ? (
                            <Alert
                                message="Error"
                                description={error.toString()}
                                type="error"
                                showIcon
                            />
                        ) : (
                            <List
                                itemLayout="vertical"
                                bordered={false}
                                size="large"
                                pagination={news?.length > 3 ? {
                                    pageSize: 3,
                                    align: 'center',
                                } : false}
                                dataSource={news}
                                loading={loading}
                                renderItem={(item: any) => (
                                    <List.Item
                                        key={item.id}
                                    >
                                        <Link to={`/news/${item.id}`}>
                                            <Typography.Title level={5} className="m-0">
                                                {item.title}
                                            </Typography.Title>
                                            <Typography.Paragraph ellipsis={{ rows: 3 }} className="m-0">
                                                {removeMarkdownFromString(item.content)}
                                            </Typography.Paragraph>
                                            <Typography.Text>{dayjs(item.dateStart).format('YYYY/MM/DD h:mm a')}</Typography.Text>
                                        </Link>
                                    </List.Item>
                                )}
                            />
                        )}
                    </Flex>
                </Card>
            }
        </>
    );
};