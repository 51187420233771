import { Button, Card, Popconfirm, Table, Tooltip } from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { SecureWithLogin, ShowIfAccess } from "../../../../utils/secure";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import LoadingSkeleton from "../../../Layout/LoadingSkeleton";
import StatusPills from '../../../crm/common/StatusPills';
import { callBackEnd } from "../../../../utils/backEndUtils";
import { useRoles } from "../../../../contexts/RolesContext";

type NSAPackagesUsersAssignProps = {
    readonly pkg: any,
    readonly namespaceId?: string
}

export default function NSAPackagesUsersAssign(props: NSAPackagesUsersAssignProps) {
    const { pkg, namespaceId } = props;
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [userPackages, setUserWithPackage] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);
    const rolesCtx = useRoles();

    useEffect(() => {
        async function getUserWithPackage() {
            try {
                setIsProcessing(true);
                const response: any = await callBackEnd('portal', 'GET', `namespaces/${namespaceId}/packages/${pkg?.uid}/users`);
                setUserWithPackage(response);
            } catch (error: any) {
                throw new Error(error);
            } finally {
                setIsProcessing(false);
            }
        }
        async function getUsers() {
            try {
                setIsProcessing(true);
                const response: any = await callBackEnd('portal', 'GET', `namespaces/${namespaceId}/users`);
                setUsers(response);
            } catch (error: any) {
                throw new Error(error);
            } finally {
                setIsProcessing(false);
            }
        }
        if (namespaceId && pkg) {
            getUserWithPackage().catch((e: any) => console.log(e));
            getUsers().catch((e: any) => console.log(e));
        }
    }, [namespaceId, pkg, refreshCounter]);

    const handleRemovePackage = async (userId: string) => {
        try {
            setIsProcessing(true);
            await callBackEnd('portal', 'DELETE', `namespaces/${namespaceId}/packages/${pkg.uid}/users/${userId}`);

        } catch (error: any) {
            throw new Error(error);
        } finally {
            setRefreshCounter(refreshCounter + 1);
        }
    }
    const handleAssignPackage = async (userId: string) => {
        ///
        try {
            setIsProcessing(true);
            await callBackEnd('portal', 'POST', `namespaces/${namespaceId}/packages/${pkg.uid}/users`, JSON.stringify({ uid: userId }));

        } catch (error: any) {
            throw new Error(error);
        } finally {
            setRefreshCounter(refreshCounter + 1);
        }
    }

    return (
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_users']} namespaceRequired={true}>
            {!isProcessing && users.length > 0 && userPackages ?

                <Card
                    title="Users"
                    type="inner"
                    extra={
                        <Link to="/nsadmin/users">
                            <Button type="primary">View all users</Button>
                        </Link>
                    }
                >
                    <Table
                        dataSource={users}
                        rowKey="uid"
                        pagination={false}
                        columns={[
                            {
                                title: 'Assigned',
                                dataIndex: 'assigned',
                                key: 'assigned',
                                render: (status: string, u: any) => {
                                    const isAssigned: boolean = userPackages.find((up: any) => up.uid === u.uid);
                                    return (
                                        <StatusPills type={isAssigned ? "enabled" : "disabled"} />
                                    )

                                },
                                filters: [
                                    { text: 'Enabled', value: true },
                                    { text: 'Disabled', value: false }
                                ],
                                onFilter: (value: any, p: any) => p.status.toLowerCase() === value,
                            },
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                                render: (name: string, user: any) => {
                                    return (
                                        <Link to={`/nsadmin/${namespaceId}/users/${user.uid}`}>
                                            <Tooltip title="Go to User details">
                                                {name}
                                            </Tooltip>

                                        </Link>
                                    )
                                }
                            },
                            {
                                title: "Email",
                                dataIndex: "email",
                                key: "email"
                            },
                            {
                                title: "Company",
                                dataIndex: "company",
                                key: "company"
                            },
                            {
                                title: 'Actions',
                                key: 'actions',
                                render: (text: string, u: any) => {
                                    const isAssigned: boolean = userPackages.find((up: any) => up.uid === u.uid);
                                    const isRestricted: boolean = (rolesCtx?.userInfos?.owner) ? false : rolesCtx?.userInfos?.id === u?.uid; //user can't edit himself except if he's owner
                                    return (
                                        <ShowIfAccess role={['nsadmin_packages', 'nsadmin_userss']} authenticated={true} namespaceRequired={true}>
                                            <>
                                                {isAssigned ?
                                                    <Popconfirm
                                                        title="Remove Package"
                                                        description="Are you sure to remove this package?"
                                                        onConfirm={() => { handleRemovePackage(u.uid) }}
                                                        onCancel={() => { }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                            <Button type="dashed" danger icon={<CloseCircleOutlined />} loading={isProcessing} disabled={isRestricted}>Remove Package</Button>
                                                        </Tooltip>
                                                    </Popconfirm>
                                                    :
                                                    <Popconfirm
                                                        title="Assign Package"
                                                        description="Are you sure to assign this package?"
                                                        onConfirm={() => { handleAssignPackage(u.uid) }}
                                                        onCancel={() => { }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                            <Button type="primary" icon={<PlusCircleOutlined />} loading={isProcessing} disabled={isRestricted}>Assign Package</Button>
                                                        </Tooltip>
                                                    </Popconfirm>
                                                }
                                            </>
                                        </ShowIfAccess>
                                    )
                                }
                            }
                        ]}
                    />

                </Card>
                :
                <LoadingSkeleton />
            }


        </SecureWithLogin>
    )
}
