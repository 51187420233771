import { Button, Card, Descriptions, Divider, Flex, Space, Table, Tooltip, Typography, notification } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, ExclamationCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { Link, useParams } from "react-router-dom";
import { SecureWithLogin, ShowIfAccess } from "../../../utils/secure";
import { faCheck, faPenToSquare, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import CustomModal from '../common/CustomModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../Layout/Loading";
import Moment from 'react-moment';
import MotionBox from '../../Layout/Animated/MotionBox';
import ShowError from '../../Layout/ShowError';
import StatusPills from '../common/StatusPills';
import { callBackEnd } from "../../../utils/backEndUtils";
import { useRoles } from '../../../contexts/RolesContext';

export default function User() {


    const { userId } = useParams();

    const [loadingUser, setLoadingUser] = useState<boolean>(true);
    const [errorUser, setErrorUser] = useState<any>(null);
    const [user, setUser] = useState<UserProfile | null>(null);

    const [loadingRoles, setLoadingRoles] = useState<boolean>(false);
    const [errorRoles, setErrorRoles] = useState<any>(null);
    const [roles, setRoles] = useState<Role[] | null>(null);
    const [rolesDefinition, setRolesDefinition] = useState<any[] | null>(null);

    const [showDisableModal, setShowDisableModal] = useState<boolean>(false);
    const [showEnableModal, setShowEnableModal] = useState<boolean>(false);
    const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false);
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);

    const [namespaceDetails, setNamespaceDetails] = useState<Namespace | null>(null);

    const rolesCtx = useRoles();
    const isRestricted: boolean = (rolesCtx?.userInfos?.owner) ? false : rolesCtx?.userInfos?.id === user?.uid; //user can't edit himself except if he's owner

    useEffect(() => {
        async function getUser() {
            try {
                setLoadingUser(true);
                const response: any = await callBackEnd('portal', 'GET', `users/${userId}`);
                setUser(response);

                if (response?.namespaceId) {
                    const namespaceResponse: any = await callBackEnd('portal', 'GET', `namespaces/${response.namespaceId}`);
                    setNamespaceDetails(namespaceResponse);

                    //Roles definition
                    const responseRolesDefinition: any = await callBackEnd('portal', 'GET', `namespaces/${response.namespaceId}/roles`).catch((e: any) => console.log(e));
                    setRolesDefinition(responseRolesDefinition);
                    //Roles
                    setLoadingRoles(true);
                    const responseRoles: any = await callBackEnd('portal', 'GET', `namespaces/${response.namespaceId}/users/${userId}/roles`).catch((e: any) => setErrorRoles(e));
                    setRoles(responseRoles.roles);
                    setLoadingRoles(false);
                }
            } catch (error: any) {
                setErrorUser(error);
            } finally {
                setLoadingUser(false);
            }
        }


        getUser().catch((e: any) => console.log(e));
    }, [userId]);

    const handleUpdateUser = (userId: any) => {
        async function updateUser(id: string) {
            try {
                const response: any = await callBackEnd('portal', 'PUT', `users/${id}/enabled`);
                setUser(response);
                notification.success({ message: "User successfully updated" });
            } catch (error: any) {
                console.log(error);
            }
        }
        if (!isRestricted) {
            updateUser(userId).catch((e: any) => console.log(e));
        }
        else {
            notification.error({ message: "You can't edit your own account", duration: 0 });
        }
    }

    const handleVerifyUser = (userId: any) => {
        async function verifyUser(id: string) {
            try {
                const response: any = await callBackEnd('portal', 'PUT', `users/${id}/verify`);
                setUser(response);
                notification.success({ message: "User successfully verified" });
            } catch (error: any) {
                console.log(error);
            }
        }
        if (!isRestricted) {
            verifyUser(userId).catch((e: any) => console.log(e));
        }
        else {
            notification.error({ message: "You can't edit your own account", duration: 0 });
        }
    }
    const handleRejectUser = (userId: any) => {
        async function rejectUser(id: string) {
            try {
                const response: any = await callBackEnd('portal', 'PUT', `users/${id}/reject`);
                setUser(response);
                notification.success({ message: "User successfully rejected" });
            } catch (error: any) {
                console.log(error);
            }
        }
        if (!isRestricted) {
            rejectUser(userId).catch((e: any) => console.log(e));
        }
        else {
            notification.error({ message: "You can't edit your own account", duration: 0 });
        }
    }

    const handleDisableModalResponse = (response: boolean) => {
        if (response) {
            setShowDisableModal(false);
            handleUpdateUser(userId);
        }
        else {
            setShowDisableModal(false);
        }
    }
    const handleEnableModalResponse = (response: boolean) => {
        if (response) {
            setShowEnableModal(false);
            handleUpdateUser(userId);
        }
        else {
            setShowEnableModal(false);
        }
    }
    const handleVerifyModalResponse = (response: boolean) => {
        if (response) {
            setShowVerifyModal(false);
            handleVerifyUser(userId);
        }
        else {
            setShowVerifyModal(false);
        }
    }
    const handleRejectModalResponse = (response: boolean) => {
        if (response) {
            setShowRejectModal(false);
            handleRejectUser(userId);
        }
        else {
            setShowRejectModal(false);
        }
    }

    const showVerifiedStatus = (value: string) => {
        switch (value) {
            case 'REGISTERED':
            case 'NAMESPACE_JOIN_REQUESTED':
                return <ExclamationCircleTwoTone />;
            case 'VERIFIED':
                return <CheckCircleTwoTone />;
            case 'REJECTED':
                return <CloseCircleTwoTone />;
        }
    }

    const getRoleDescription = (role: string) => {
        if (rolesDefinition) {
            const roleDefinition = rolesDefinition.find((r) => r.name === role);
            return roleDefinition?.description;
        }
        return '';
    }

    return (
        <SecureWithLogin callbackPath="/users" role={['crm_staff']} namespaceRequired={true}>
            {(errorUser === null && !loadingUser && user !== null) ?
                <MotionBox>
                    <Flex vertical gap="large">
                        <Card
                            title={<><UserOutlined /> {user.email}</>}
                            className="users"
                            extra={
                                <ShowIfAccess role={['crm_users']} namespaceRequired={false}>
                                    <Space size="middle">
                                        <Link to={`/users/namespace/${userId}`}>
                                            <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                <Button type="primary" icon={<FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>} disabled={isRestricted}>
                                                    Assign to Namespace
                                                </Button>
                                            </Tooltip>
                                        </Link>
                                        <Link to={`/users/edit/${userId}`}>
                                            <Button type="primary" icon={<FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>}>
                                                Edit profile
                                            </Button>
                                        </Link>
                                        {(user.status === 'VERIFIED' && namespaceDetails !== null) &&
                                            <Link to={`/users/${userId}/${namespaceDetails.uid}/roles`}>
                                                <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                    <Button type="primary" icon={<FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>} disabled={isRestricted}>
                                                        Edit roles
                                                    </Button>
                                                </Tooltip>
                                            </Link>
                                        }
                                        {user.status !== 'VERIFIED' &&
                                            <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                <Button type="primary" onClick={() => setShowVerifyModal(true)} icon={<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>} disabled={isRestricted}>
                                                    Verify user
                                                </Button>
                                            </Tooltip>

                                        }
                                        {(namespaceDetails === null) &&
                                            <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                <Button type="primary" danger onClick={() => setShowRejectModal(true)} icon={<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>} disabled={isRestricted}>
                                                    Reject user
                                                </Button>
                                            </Tooltip>
                                        }
                                        {user.enabled &&
                                            <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                <Button type="primary" danger onClick={() => setShowDisableModal(true)} icon={<FontAwesomeIcon icon={faToggleOff}></FontAwesomeIcon>} disabled={isRestricted}>
                                                    Disable user
                                                </Button>
                                            </Tooltip>
                                        }
                                        {!user.enabled &&
                                            <Tooltip title={isRestricted && "You can not edit your own account"}>
                                                <Button type="primary" onClick={() => setShowEnableModal(true)} icon={<FontAwesomeIcon icon={faToggleOn}></FontAwesomeIcon>} disabled={isRestricted}>
                                                    Enable user
                                                </Button>
                                            </Tooltip>
                                        }
                                    </Space>
                                </ShowIfAccess>
                            }
                        >
                            <Typography.Title level={5}>User profile</Typography.Title>
                            <Descriptions>
                                <Descriptions.Item label="Firstname">{user?.firstName}</Descriptions.Item>
                                <Descriptions.Item label="Lastname">{user?.lastName}</Descriptions.Item>
                                <Descriptions.Item label="E-Mail">{user?.email}</Descriptions.Item>
                                <Descriptions.Item label="Company">{user?.company}</Descriptions.Item>
                                {namespaceDetails &&
                                    <Descriptions.Item label="Namespace">
                                        <Tooltip title="Go to Namespace details">
                                            <Link to={`/namespaces/${user?.namespaceId}`}>
                                                {namespaceDetails.label} ({namespaceDetails.name})
                                            </Link>
                                        </Tooltip>
                                    </Descriptions.Item>
                                }
                            </Descriptions>
                            <Divider />
                            <Typography.Title level={5}>Technical informations</Typography.Title>
                            <Descriptions>
                                <Descriptions.Item label="Uid">{user?.uid}</Descriptions.Item>
                                <Descriptions.Item label="Enabled"><Space size={3} align='center'><StatusPills type={user?.enabled} /> {user?.enabled.toString()}</Space></Descriptions.Item>
                                <Descriptions.Item label="Status"><Space size={3} align='center'>{showVerifiedStatus(user?.status)}{user?.status}</Space></Descriptions.Item>
                                <Descriptions.Item label="Last login"><Moment date={user?.lastLogin} /></Descriptions.Item>
                            </Descriptions>

                        </Card>


                        {(errorRoles === null && !loadingRoles && roles !== null && rolesDefinition !== null) ?
                            <Card
                                title="Roles and available features"
                                type="inner"
                            >
                                {roles.length > 0 ?
                                    <Table
                                        size='small'
                                        pagination={false}
                                        dataSource={roles}
                                        rowKey="name"
                                        scroll={{ x: 'max-content' }}
                                        columns={[
                                            {
                                                title: 'Granted Role',
                                                dataIndex: 'name',
                                                key: 'name',
                                                render: (value: string) => {
                                                    return getRoleDescription(value)
                                                }
                                            },
                                            {
                                                title: 'Environments',
                                                dataIndex: 'environments',
                                                key: 'environments',
                                                render: (value: string[], r: any) => {
                                                    if (value?.length > 0) {
                                                        return value.join(', ');
                                                    }
                                                    else {
                                                        return 'Feature not environment-related';
                                                    }
                                                }
                                            }
                                        ]}
                                    />
                                    :
                                    <p>No roles found</p>
                                }
                            </Card>
                            :
                            <>
                                {loadingRoles && <Loading />}
                                {errorRoles && <ShowError error={errorRoles.toString()} />}
                            </>

                        }
                    </Flex>
                    {showDisableModal && user !== null &&
                        <CustomModal secureString={user.email} title="Disable user" message={`Are you sure you want to disable the user ${user.email} ? Please type in the name of the user "${user.email}" as a confirmation.`} callback={handleDisableModalResponse} />
                    }
                    {showEnableModal && user !== null &&
                        <CustomModal secureString={user.email} title="Enable user" message={`Are you sure you want to enable the user ${user.email} ? Please type in the name of the user "${user.email}" as a confirmation.`} callback={handleEnableModalResponse} />
                    }
                    {showVerifyModal && user !== null &&
                        <CustomModal title="Verify user" message={`Are you sure you want to verify the user ${user.email} ?`} callback={handleVerifyModalResponse} />
                    }
                    {showRejectModal && user !== null &&
                        <CustomModal title="Reject user" message={`Are you sure you want to reject the user ${user.email} ?`} callback={handleRejectModalResponse} />
                    }
                </MotionBox>
                :
                <>
                    {loadingUser && <Loading />}
                    {errorUser && <ShowError error={errorUser.toString()} />}
                </>
            }
        </SecureWithLogin>
    )
}
