import { useEffect, useState } from "react";

import { useRoles } from "../contexts/RolesContext";

type StatusMessage = {
    message: string,
    type: string,
    display: boolean
}

export default function useUserInfos() {
    const [userInfos, setUserInfos] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [formattedStatus, setFormattedStatus] = useState<any>(null);
    
    const rolesCtx = useRoles();

    useEffect(() => {
        if (rolesCtx) {
            if(!rolesCtx?.loading) {
                setUserInfos(rolesCtx?.userInfos);
                const formattedStatus = (status: string): StatusMessage => {
                    switch (status) {
                        case 'REGISTERED':
                            return {
                                message: 'Your account is pending approval from an administrator',
                                type: 'info',
                                display: true
                            };
                        case 'NAMESPACE_JOIN_REQUESTED':
                            return {
                                message: 'Your account is pending approval from a namespace administrator',
                                type: 'info',
                                display: true
                            };
                        case 'DETACHED':
                            return {
                                message: "Your account has been removed from it's namespace",
                                type: 'warning',
                                display: true
                            };
                        case 'VERIFIED':
                            return {
                                message: 'Your account is active',
                                type: 'success',
                                display: false
                            };
                        case 'REJECTED':
                            return {
                                message: 'Your account has been rejected',
                                type: 'warning',
                                display: true
                            };
                        default:
                            return {
                                message: '',
                                type: '',
                                display: false
                            };
                    }
                }
                setFormattedStatus(formattedStatus(rolesCtx?.userInfos?.status));
            }
            setLoading(rolesCtx?.loading);
            return;
        }
    }, [rolesCtx]);

    return { userInfos, loading, formattedStatus };
}