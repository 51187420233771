import { Button, Result, Space } from 'antd';
import { FC, PropsWithChildren, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useOidcAccessToken as getAccessToken, useOidc as getOidc, useOidcUser as getUser } from '@axa-fr/react-oidc';

import { EnvContext } from '../contexts/EnvironmentContext';
import LoadingSkeleton from '../components/Layout/LoadingSkeleton';
import { useRoles } from '../contexts/RolesContext';

const isInStringArray = (stringToFind: string[], stringCollection: string[]): boolean => {
    for (let str of stringToFind) {
        if (!stringCollection.includes(str)) {
            return false;
        }
    }
    return true;
}

const getRoles = (rolesCtx: any, env: string): string[] => {
    if (!rolesCtx.loading) {
        return rolesCtx?.roles?.map((r: any) => r.name);
    }
    else {
        return [];
    }
}
const hasModule = (allModules: any, module: any): boolean => {
    return allModules[module.toUpperCase()] || false
}

export type SecureProps = {
    callbackPath?: string;
    role?: string[]
    module?: string;
    namespaceRequired?: boolean;
};

export type AccessProps = {
    role?: string[];
    authenticated?: boolean;
    namespaceRequired?: boolean;
    module?: string;
};

export type OwnEditsProps = {
    targetUserId: string;
};

//Used to secure access to components depending on the user's role
export const SecureWithLogin: FC<PropsWithChildren<SecureProps>> = ({ children, callbackPath = null, role = null, module = null, namespaceRequired = false }) => {
    const { isAuthenticated } = getOidc('config');

    const env: string = useContext(EnvContext);
    const navigate = useNavigate();
    const rolesCtx = useRoles();

    if (rolesCtx!.loading) {
        return <LoadingSkeleton />;
    }

    if (!isAuthenticated) {
        // return <LoginEmail path={callbackPath} />;
        navigate('/');
    }

    if (role !== null) {
        const roles: string[] = getRoles(rolesCtx, env);
        const allModules = rolesCtx?.modules;
        const hasModuleAccess = module !== null ? hasModule(allModules, module) : true;

        if (hasModuleAccess) {
            if (roles && roles?.length > 0 && isInStringArray(role, roles)) {
                if (!namespaceRequired || (namespaceRequired && rolesCtx?.namespaceId !== null)) {
                    return <>{children}</>;
                }
            }
            else if (hasModuleAccess && role.length === 0) {
                return <>{children}</>;
            }
        }

    }
    return <Result
        status={"403"}
        title="Access Denied"
        subTitle="You do not have access to this module"
        extra={
            <Link to="/"><Button type="primary">Go back to home page</Button></Link>
        }
    />
};

export const ShowIfAccess: FC<PropsWithChildren<AccessProps>> = ({ children, role = null, authenticated = true, module = null, namespaceRequired = false }) => {
    const { isAuthenticated } = getOidc('config');
    const env: string = useContext(EnvContext);
    const rolesCtx = useRoles();

    if (namespaceRequired && !rolesCtx?.namespaceId) {
        return <></>;
    }

    if (!authenticated && !isAuthenticated) {
        return <>{children}</>;
    }

    if (authenticated && isAuthenticated && role === null) {
        return <>{children}</>;
    }
    const roles: string[] = getRoles(rolesCtx, env);
    if (authenticated && isAuthenticated && role !== null && roles?.length > 0) {
        const allModules = rolesCtx?.modules;
        const hasModuleAccess = module !== null ? hasModule(allModules, module) : true;
        if (hasModuleAccess) {
            if (roles && roles.length > 0 && isInStringArray(role, roles)) {
                if (!namespaceRequired || (namespaceRequired && (rolesCtx?.namespaceId !== null || rolesCtx?.namespaceId !== undefined))) {
                    return <>{children}</>;
                }
            }
        }

    }

    return <></>;
};


export const SecureOwnEdits: FC<PropsWithChildren<OwnEditsProps>> = ({ children, targetUserId }) => {
    const { isAuthenticated } = getOidc('config');
    const navigate = useNavigate();

    const rolesCtx = useRoles();
    const isRestricted: boolean = (rolesCtx?.userInfos?.owner) ? false : rolesCtx?.userInfos?.id === targetUserId;

    if (!isAuthenticated) {
        navigate('/');
    }

    if (!isRestricted) {
        return <>{children}</>;
    }

    return <Result
        status={"403"}
        title="Access Denied"
        subTitle="You can not edit your own account"
        extra={
            <Space size="middle">
                <Button type="primary" onClick={() => navigate(-1)}>Go back</Button>
                <Link to="/">
                    <Button type="primary">Go back to homepage</Button>
                </Link>
            </Space>
        }
    />
}

export const ToggleIfAccess: React.FC<ToggleIfAccessProps> = ({ role, authenticated, contentPassed, contentFailed }) => {
    const { isAuthenticated } = getOidc('config');
    const env: string = useContext(EnvContext);
    const rolesCtx = useRoles();


    if (!authenticated && !isAuthenticated) {
        return contentPassed;
    }

    if (authenticated && isAuthenticated && role === null) {
        return contentPassed;
    }
    const roles: string[] = getRoles(rolesCtx, env);
    if (authenticated && isAuthenticated && role !== null && roles?.length > 0) {


        if (isInStringArray(role, roles)) {
            return contentPassed;
        }
    }

    return contentFailed;
};

export const useOidcAccessToken = () => {
    return getAccessToken('config');
}

export const useOidcUser = () => {
    return getUser('config')
}

export const useOidc = () => {
    return getOidc('config')
}

export const getProductsAccess = (accessTokenPayload: any, env: string): string[] => {
    const jetProducts: string[] = [];
    let resourceAccess = accessTokenPayload?.accessTokenPayload.resource_access;
    if (resourceAccess[env.toLocaleLowerCase()]?.roles?.includes('js_jetscan')) {
        jetProducts.push('jetscan');
    }
    if (resourceAccess[env.toLocaleLowerCase()]?.roles?.includes('js_jetflow')) {
        jetProducts.push('jetflow');
    }
    return jetProducts;
}

export const getTokenAvailableProduct = (accessTokenPayload: any, env: string) => {
    if (accessTokenPayload?.volumes) {

        const volumesOfEnv: any = accessTokenPayload?.volumes[env.toLowerCase()]
        return ([
            { jetscan: volumesOfEnv?.hasOwnProperty('jetscan') },
            { jetflow: volumesOfEnv?.hasOwnProperty('jetflow') }
        ]);
    }
    else {
        return ([
            { jetscan: false },
            { jetflow: false }
        ]);
    }
}