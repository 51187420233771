import { useEffect, useState } from "react";

import { Flex } from 'antd';
import Loading from "../../../Layout/Loading";
import MotionBox from "../../../Layout/Animated/MotionBox";
import NSAPendingUsersList from "./../NSAPendingUsersList";
import NSAUsersList from "../NSAUsersList";
import { SecureWithLogin } from "../../../../utils/secure";
import ShowError from "../../../Layout/ShowError";
import { callBackEnd } from "../../../../utils/backEndUtils";
import { useOutletContext } from "react-router-dom";
import { useRoles } from "../../../../contexts/RolesContext";

export default function NSAUsers() {
    const outletContext: { setHeaderTitle: React.Dispatch<React.SetStateAction<string>> } = useOutletContext();
    useEffect(() => {
        outletContext?.setHeaderTitle("My Users");
    }, [outletContext]);

    const [namespaceId, setNamespaceId] = useState<string>("");

    const [refresher, setRefresher] = useState<boolean>(false);

    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
    const [errorUsers, setErrorUsers] = useState<boolean>(false);
    const [users, setUsers] = useState<UserProfile[] | null>(null);
    const [loadingPendingUsers, setLoadingPendingUsers] = useState<boolean>(true);
    const [errorPendingUsers, setErrorPendingUsers] = useState<boolean>(false);
    const [pendingUsers, setPendingUsers] = useState<UserProfile[] | null>(null);

    const rolesCtx = useRoles();

    useEffect(() => {
        if (rolesCtx?.namespaceId) {
            setNamespaceId(rolesCtx?.namespaceId);
        }
    }, [rolesCtx]);

    useEffect(() => {
        async function getUsers() {
            try {
                setLoadingUsers(true);
                const response: any = await callBackEnd('portal', 'GET', `namespaces/${namespaceId}/users`);
                setUsers(response);

            } catch (error: any) {
                setErrorUsers(error);
            } finally {
                setLoadingUsers(false);
            }
        }

        async function getPendingUsers() {
            try {
                setLoadingPendingUsers(true);
                const response: any = await callBackEnd('portal', 'GET', `namespaces/${namespaceId}/join`);
                setPendingUsers(response);
            } catch (error: any) {
                setErrorPendingUsers(error);
            } finally {
                setLoadingPendingUsers(false);
            }
        }

        if (namespaceId !== '') {
            getUsers().catch((e: any) => console.log(e));
            getPendingUsers().catch((e: any) => console.log(e));
        }
    }, [namespaceId, refresher]);

    const refreshUsers = () => {
        setRefresher(!refresher);
    }

    return (

        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_users']} namespaceRequired={true}>
            {/* {(!errorNamespace && !loadingNamespace && namespace !== null) ? */}
            <MotionBox>
                <Flex gap="middle" vertical>
                    {pendingUsers !== null && pendingUsers.length > 0 &&
                        <>
                            {(!errorPendingUsers && !loadingPendingUsers && pendingUsers !== null) ?
                                <>
                                    {(namespaceId !== undefined) ?
                                        <NSAPendingUsersList users={pendingUsers} title='Pending Users' refreshUsers={refreshUsers} />
                                        :
                                        <Loading />
                                    }
                                </>
                                :
                                <>
                                    {loadingUsers && <Loading />}
                                    {errorUsers && <ShowError error={errorUsers.toString()} />}
                                </>
                            }
                        </>
                    }
                    {(!errorUsers && !loadingUsers && users !== null) ?
                        <>
                            {(namespaceId !== undefined) ?
                                <NSAUsersList namespaceId={namespaceId} users={users} title='Users in Namespace' />
                                :
                                <Loading />
                            }
                        </>
                        :
                        <>
                            {loadingUsers && <Loading />}
                            {errorUsers && <ShowError error={errorUsers.toString()} />}
                        </>
                    }

                </Flex>
            </MotionBox>
        </SecureWithLogin>
    )
}
