import './../../assets/scss/News.scss';

import { Alert, Card, Col, Flex, Input, Result, Row } from 'antd';
import { HomeOutlined, SearchOutlined } from '@ant-design/icons';
import { SecureWithLogin, ShowIfAccess } from "../../utils/secure";
import { filterNews, showEnvPill } from './utils';

import { Content } from 'antd/es/layout/layout';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import MotionBox from "../Layout/Animated/MotionBox";
import NewsCard from './NewsCard';
import { PageHeader } from '../Layout/header/PageHeader';
import ShowError from '../Layout/ShowError';
import useNews from "../../hooks/useNews";
// import SectionBanner from '../Layout/SectionBanner';
import { useState } from 'react';

export default function News() {
    const { news, loading, error } = useNews(); //loading news
    const [search, setSearch] = useState<string>('');
    const filteredNews = filterNews(news, search);

    return (
        <SecureWithLogin callbackPath="/news" module='NEWS' role={[]} namespaceRequired={true}>
            {(!loading && error === null) ?
                <MotionBox>
                    <Content className="news">
                        <PageHeader
                            title='News'
                            subtitle='Portal'
                            breadcrumbs={[
                                { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                                { title: <span>News</span> }]}
                        />
                        <Flex vertical gap="middle">
                        <ShowIfAccess authenticated={true} role={['crm_faq_writer']} namespaceRequired={true}>
                            <Alert message="You have are viewing this page as a writer" type="success" showIcon />
                        </ShowIfAccess>
                        <Row gutter={[24, 24]}>
                            <Col xs={24} >
                                <Card>
                                    <Input
                                        addonBefore={<SearchOutlined />}
                                        style={{ width: '100%', flex: 1 }}
                                        type="text"
                                        placeholder="Search keywords"
                                        name="search"
                                        className="search"
                                        onChange={(e: any) => setSearch(e.target.value)}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} >
                                <Row gutter={[24, 24]} >
                                    {filteredNews?.map((n: any) => {
                                        return (
                                            <Col key={n.id} xs={24} xxl={12}>
                                                <div className="newsItem"><NewsCard news={n} showEnvPill={showEnvPill} key={n.id} /></div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Col>

                        </Row>
                        </Flex>
                    </Content>
                </MotionBox>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error.toString()} />}
                    {news === null && !loading && <Result status="404" title="404" subTitle="Sorry, the news you are looking for does not exist." />}
                </>
            }
        </SecureWithLogin >
    )
}
