import { Avatar, Menu, Popover, Tooltip } from 'antd';
import React, { useState } from 'react';
import { faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { useOidc, useOidcUser } from '../../utils/secure';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MenuProps } from 'antd';
import { NavLink } from 'react-router-dom';
import useUserInfos from '../../hooks/useUserInfos';

const AccountMenu = () => {
    const { oidcUser, }: { oidcUser: any } = useOidcUser();
    const { formattedStatus } = useUserInfos();
    const { logout } = useOidc();
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenChange = (open: boolean) => {
        setIsOpen(open);
    };

    const items: MenuProps['items'] = [
        {
            label: <NavLink to='/account'><FontAwesomeIcon icon={faUser} style={{ marginRight: '.5rem' }} />My Account</NavLink>,
            key: '/account',
        },
        {
            label: <NavLink to="/" onClick={() => logout(null, { client_id: 'api', id_token_hint: undefined!, post_logout_redirect_uri: window.location.origin })}>
                <FontAwesomeIcon icon={faRightFromBracket} style={{ marginRight: '.5rem' }} />
                Logout
            </NavLink>,
            key: '3',
        },
    ];

    const getAvatarName = (oidcUser: any) => {
        if (oidcUser.given_name !== '' && oidcUser.family_name !== '') {
            return oidcUser?.given_name?.charAt(0).toUpperCase() + oidcUser?.family_name?.charAt(0).toUpperCase();
        }
        else {
            return oidcUser?.name?.charAt(0).toUpperCase();
        }
    };

    const getAvatarColor = (formattedStatus: any) => {
        switch (formattedStatus?.status?.type) {
            case 'info':
                return '#1890ff';
            case 'success':
                return '#22c55e';
            case 'warning':
                return '#faad14';
            default:
                return '#22c55e';
        }
    }

    return (

        <Tooltip placement="left" title="Account management">
            <Popover
                placement="bottomRight"
                style={{ margin: '24px' }}
                open={isOpen}
                onOpenChange={handleOpenChange}
                trigger="click"
                content={
                    <Menu theme="light"
                        className='account-menu'
                        mode="inline"
                        style={{ width: 256, border: 'none' }}
                        defaultOpenKeys={["accessAndEnablement"]}
                        items={items} onClick={() => handleOpenChange(false)} />
                }>
                <button className='userMenu'>
                    {oidcUser?.name ?
                        <Avatar size={40} style={{ backgroundColor: getAvatarColor(formattedStatus), verticalAlign: 'middle' }} >
                            {getAvatarName(oidcUser)}
                        </Avatar>
                        : <>Loading ...</>}
                </button>
            </Popover>
        </Tooltip>

    )
}
export default React.memo(AccountMenu)