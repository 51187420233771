import { ApiOutlined, AppstoreAddOutlined, BankOutlined, BarChartOutlined, CaretDownOutlined, CloudServerOutlined, CustomerServiceOutlined, DashboardOutlined, FundViewOutlined, HomeOutlined, InfoCircleOutlined, MailOutlined, PlayCircleOutlined, QuestionCircleOutlined, ReadOutlined, TagOutlined, ToolOutlined, UnorderedListOutlined, UsergroupAddOutlined } from '@ant-design/icons';

import { NavLink } from 'react-router-dom';
import { useMemo } from 'react';
import { useOidc } from '../utils/secure';
import { useRoles } from '../contexts/RolesContext';

export const useFeatures = () => {

    const { isAuthenticated } = useOidc();
    const rolesCtx = useRoles();

    const data: Feature[] = useMemo(() => {
        if (!isAuthenticated) {
            return [];
        }
        else if (isAuthenticated && rolesCtx?.roles === null) {
            return [{
                key: "home",
                label: <NavLink to={"/"} >Home</NavLink>,
                link: "/",
                icon: <HomeOutlined />,
            },
            {
                key: "faq",
                label: <NavLink to={"/faq"} >FAQ</NavLink>,
                link: "/faq",
                icon: <QuestionCircleOutlined />,
            }]
        }
        else {
            const rolesFiltered = rolesCtx?.roles?.filter((role: any) => !role.name.startsWith('module_'));
            const modules = rolesCtx?.modules;
            const hasRole = (role: string) => {
                return rolesFiltered?.some((r: any) => role.includes(r.name));
            }

            const hasModule = (module: any): boolean => {
                if (!modules) return false;
                return modules[module.toUpperCase()] || false
            }

            const isDisabled = (roles: string[], namespaceRequired: boolean) => {
                let disabled = false;
                roles.forEach((role: any) => {
                    if (!hasRole(role)) {
                        disabled = true;
                    }
                });

                if (namespaceRequired && rolesCtx?.namespaceId === null) {
                    disabled = true;
                }
                return disabled;
            }

            const menuElements: Feature[] = [
                {
                    key: "home",
                    label: "Home",
                    description: "Welcome to the Neterium User Portal",
                    link: "/",
                    icon: <HomeOutlined />,
                    role: [],
                    module: [],
                    showInMenu: true,
                    enabled: true,
                    namespaceRequired: false,
                },
                {
                    key: "gettingStarted",
                    label: <>Getting Started<CaretDownOutlined className='caretIcon' /></>,
                    description: "Use this section to get started with the Neterium API.",
                    link: "",
                    icon: <PlayCircleOutlined />,
                    role: [],
                    module: [],
                    showInMenu: true,
                    enabled: true,
                    namespaceRequired: true,
                    children: [
                        {
                            key: "try-the-api",
                            label: "Try the API",
                            description: "Use this section to try the Neterium API directly.",
                            link: "/getting-started/try-the-api",
                            icon: <PlayCircleOutlined />,
                            role: ['js_screen'],
                            module: ['TRY_IT'],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true
                        },
                        {
                            key: "guidedTour",
                            label: "Guided Tour",
                            description: "Use this section to get a guided tour of the Neterium API.",
                            link: "/getting-started/guided-tour",
                            icon: <PlayCircleOutlined />,
                            role: [],
                            module: [],
                            showInMenu: true,
                            enabled: false,
                            namespaceRequired: true
                        }
                    ]
                },
                {
                    key: "documentation",
                    label: <>Documentation<CaretDownOutlined className='caretIcon' /></>,
                    description: "Use this section to get started with the Neterium API.",
                    link: "",
                    icon: <ReadOutlined />,
                    role: [],
                    module: [],
                    showInMenu: true,
                    enabled: true,
                    namespaceRequired: true,
                    children: [
                        {
                            key: "doc",
                            label: "API Documentation",
                            description: "Access the full documentation of the Neterium API, including examples that you can use to try the API directly.",
                            link: "/documentation/api-documentation",
                            icon: <ReadOutlined />,
                            role: [],
                            module: ['API_DOCUMENTATION'],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true
                        },
                        {
                            key: "release-notes",
                            label: "Release Notes",
                            description: "Read about all enhancements and fixes made to each release of the API.",
                            link: "/documentation/release-notes",
                            icon: <TagOutlined />,
                            role: [],
                            module: ['RELEASE_NOTES'],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true
                        }
                    ]
                },
                {
                    key: "userGuide",
                    label: <>User Guide<CaretDownOutlined className='caretIcon' /></>,
                    description: "Use this section to get started with the Neterium API.",
                    link: "",
                    icon: <InfoCircleOutlined />,
                    role: [],
                    module: [],
                    showInMenu: true,
                    enabled: false,
                    namespaceRequired: true,
                    children: [
                        {
                            key: "screening",
                            label: "Screening",
                            description: "Use this section to get started with the Neterium Screening API.",
                            link: "/user-guide/screening",
                            icon: <InfoCircleOutlined />,
                            role: [],
                            module: [],
                            showInMenu: true,
                            enabled: false,
                            namespaceRequired: true,
                        },
                        {
                            key: "integration",
                            label: "Integration",
                            description: "Use this section to get started with the Neterium Screening API.",
                            link: "/user-guide/integration",
                            icon: <InfoCircleOutlined />,
                            role: [],
                            module: [],
                            showInMenu: true,
                            enabled: false,
                            namespaceRequired: true,
                        },
                        {
                            key: "tuning",
                            label: "Tuning",
                            description: "Use this section to get started with the Neterium Screening API.",
                            link: "/user-guide/tuning",
                            icon: <InfoCircleOutlined />,
                            role: [],
                            module: [],
                            showInMenu: true,
                            enabled: false,
                            namespaceRequired: true,
                        },
                        {
                            key: "testing",
                            label: "Testing",
                            description: "Use this section to get started with the Neterium Screening API.",
                            link: "/user-guide/testing",
                            icon: <InfoCircleOutlined />,
                            role: [],
                            module: [],
                            showInMenu: true,
                            enabled: false,
                            namespaceRequired: true,
                        },
                        {
                            key: "list-management",
                            label: "List Management",
                            description: "Use this section to get started with the Neterium Screening API.",
                            link: "/user-guide/list-management",
                            icon: <InfoCircleOutlined />,
                            role: [],
                            module: [],
                            showInMenu: true,
                            enabled: false,
                            namespaceRequired: true,
                        },
                        {
                            key: "best-practices",
                            label: "Best Practices",
                            description: "Use this section to get started with the Neterium Screening API.",
                            link: "/user-guide/best-practices",
                            icon: <InfoCircleOutlined />,
                            role: [],
                            module: [],
                            showInMenu: true,
                            enabled: false,
                            namespaceRequired: true,
                        }
                    ]
                },
                {
                    key: "tools",
                    label: <>Tools<CaretDownOutlined className='caretIcon' /></>,
                    description: "Use this section to get started with the Neterium API.",
                    link: "",
                    icon: <ToolOutlined />,
                    role: [],
                    module: [],
                    showInMenu: (isAuthenticated && rolesFiltered?.some((role: any) => ['portal_model_validation', 'js_screen'].includes(role.name))),
                    enabled: true,
                    namespaceRequired: true,
                    children: [
                        {
                            key: "model-validation",
                            label: 'Model Validation',
                            description: 'This module allows you to perform a complete effectiveness and efficiency diagnostic of your screening parameters, and produces a full summary report allowing to compare settings and tune the service to your risk appetite.',
                            link: '/tools/model-validation',
                            icon: <FundViewOutlined />,
                            role: ['portal_model_validation', 'js_screen'],
                            module: ['MODEL_VALIDATION'],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true
                        }
                    ]
                },
                {
                    key: "status",
                    label: <>Status<CaretDownOutlined className='caretIcon' /></>,
                    description: "Check the status of the Neterium API services.",
                    link: "",
                    icon: <CloudServerOutlined />,
                    role: [],
                    module: [],
                    showInMenu: true,
                    enabled: true,
                    namespaceRequired: true,
                    children: [
                        {
                            key: "dashboard",
                            label: "Dashboard",
                            description: "Welcome to the Neterium User Portal",
                            link: "/status/dashboard",
                            icon: <DashboardOutlined />,
                            role: [],
                            module: [],
                            showInMenu: true,
                            enabled: false,
                            namespaceRequired: false
                        },
                        {
                            key: "cluster-status",
                            label: "Service Status",
                            description: "Display the current status of all the services and data collections of the Neterium API.",
                            link: "/status/cluster-status",
                            icon: <CloudServerOutlined />,
                            role: ['portal_cluster_status'],
                            module: ['SERVICE_STATUS'],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true
                        },
                        {
                            key: "list-status",
                            label: "List Status",
                            description: "Consult the status of each collection, including update times and changes, as well as full list statistics.",
                            link: "/status/list-status",
                            icon: <UnorderedListOutlined />,
                            role: ['portal_list_status'],
                            module: ['LIST_STATUS'],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true
                        },
                        {
                            key: "stats",
                            label: "Statistics",
                            description: "Use this section to view the Neterium API usage.",
                            link: "/status/stats",
                            icon: <BarChartOutlined />,
                            role: ['js_stats'],
                            module: ['STATISTICS'],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true
                        }
                    ]
                },
                {
                    key: "faq",
                    label: 'FAQ',
                    description: 'Search the most common questions and issues in using the Neterium screening service.',
                    link: '/faq',
                    icon: <QuestionCircleOutlined />,
                    role: [],
                    module: [],
                    showInMenu: true,
                    enabled: true,
                    namespaceRequired: false
                },
                {
                    key: "news",
                    label: "News",
                    description: "News and updates about your Neterium services",
                    link: "/news",
                    icon: <MailOutlined />,
                    role: [],
                    module: ['NEWS'],
                    showInMenu: true,
                    enabled: false,
                    namespaceRequired: true
                },
                {
                    key: "support",
                    label: 'Support',
                    description: 'Get in touch with our support team for any questions or issues you may have.',
                    link: '/support',
                    icon: <CustomerServiceOutlined />,
                    role: [],
                    module: ['SUPPORT'],
                    showInMenu: true,
                    enabled: false,
                    namespaceRequired: true
                },
                {
                    key: 'administration',
                    label: <>Administration<CaretDownOutlined className='caretIcon' /></>,
                    description: 'Administration',
                    link: '',
                    icon: <BankOutlined />,
                    role: [],
                    module: [],
                    showInMenu: (isAuthenticated && rolesFiltered?.some((role: any) => ["nsadmin_packages", "nsadmin_users", "nsadmin_service_accounts"].includes(role.name))),
                    enabled: true,
                    namespaceRequired: true,
                    children: [
                        {
                            key: "packages",
                            label: "My Packages",
                            description: "Manage your packages",
                            link: "/nsadmin/packages",
                            icon: <AppstoreAddOutlined />,
                            role: ['nsadmin_packages'],
                            module: [],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true
                        },
                        {
                            key: "users",
                            label: "My Users",
                            description: "Manage your users",
                            link: "/nsadmin/users",
                            icon: <UsergroupAddOutlined />,
                            role: ['nsadmin_users'],
                            module: [],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true
                        },
                        {
                            key: "service-accounts",
                            label: "My Service Accounts",
                            description: "Manage your service accounts",
                            link: "/nsadmin/service-accounts",
                            icon: <ApiOutlined />,
                            role: ['nsadmin_service_accounts'],
                            module: [],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true

                        }
                    ]
                },
                {
                    key: 'accessAndEnablement',
                    label: <>Access & Enablement<CaretDownOutlined className='caretIcon' /></>,
                    description: 'Access & Enablement',
                    link: '',
                    icon: <BankOutlined />,
                    role: [],
                    module: [],
                    showInMenu: (isAuthenticated && rolesFiltered?.some((role: any) => ["crm_namespaces", "crm_users"].includes(role.name))),
                    enabled: true,
                    namespaceRequired: true,
                    children: [
                        {
                            key: 'allNamespaces',
                            label: 'All Namespaces',
                            description: 'All Namespaces',
                            link: '/namespaces',
                            icon: <BankOutlined />,
                            role: ["crm_namespaces"],
                            module: [],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true,
                            danger: true
                        },
                        {
                            key: 'allUsers',
                            label: 'All Users',
                            description: 'All Users',
                            link: '/users',
                            icon: <UsergroupAddOutlined />,
                            role: ["crm_users"],
                            module: [],
                            showInMenu: true,
                            enabled: true,
                            namespaceRequired: true,
                            danger: true
                        }
                    ]
                }
            ]

            let processedMenuElements: Feature[] = menuElements
                .filter((feature: Feature) => feature?.enabled && feature?.showInMenu)
                .filter((feature: Feature) => (feature?.module.length > 0) ? hasModule(feature.module[0]) : true)
                .map((feature: Feature) => {
                    if (feature.enabled && isAuthenticated) {
                        if (feature.children) {
                            feature.children = feature.children
                                .filter(child => child.enabled && child.showInMenu)
                                .filter(child => (child.module.length > 0) ? hasModule(child.module[0]) : true)
                                .map(child => ({
                                    ...child,
                                    label: (!isDisabled(child.role, child.namespaceRequired) && child.link !== '')
                                        ? <NavLink to={child.link}>{child.label}</NavLink>
                                        : child.label,
                                    disabled: isDisabled(child.role, child.namespaceRequired)
                                }));
                        }

                        let f: Feature = {
                            ...feature,
                            label: (!isDisabled(feature.role, feature.namespaceRequired) && feature.link !== '') ? <NavLink to={feature.link}>{feature.label}</NavLink> : feature.label,
                            disabled: isDisabled(feature.role, feature.namespaceRequired)
                        }

                        if (f.hasOwnProperty('children') && f?.children?.length === 0) {
                            f.showInMenu = false;
                        } else if (f.hasOwnProperty('children') && f?.children?.every(child => child.disabled)) {
                            f.disabled = true;
                        }

                        return f;
                    }
                    return feature;
                });


            let cleanedUpElements: any[] = processedMenuElements
                .filter((feature: Feature) => feature.showInMenu)
                .map((feature: any) => {
                    if (feature.children) {
                        feature.children.map((child: any) => {
                            delete child.description;
                            // delete child.link;
                            delete child.role;
                            delete child.showInMenu;
                            delete child.enabled;
                            delete child.namespaceRequired;

                            return {
                                key: child.key,
                                label: child.label,
                                link: child.link,
                                icon: child.icon,
                                disabled: child.disabled,
                                ...(child?.danger && { danger: child.danger })
                            };
                        });
                    }
                    delete feature.description;
                    // delete feature.link;
                    delete feature.role;
                    delete feature.showInMenu;
                    delete feature.enabled;
                    delete feature.namespaceRequired;

                    feature = {
                        key: feature.key,
                        label: feature.label,
                        link: feature.link,
                        icon: feature.icon,
                        disabled: feature.disabled,
                        ...(feature.children && { children: feature.children }),
                        ...(feature?.danger && { danger: feature.danger })
                    }
                    return feature;
                });
            return cleanedUpElements;
        }
    }, [isAuthenticated, rolesCtx]);

    return data;
}