import { Button, Card, Input, Space, Table, TableColumnsType, Tag, Tooltip } from 'antd';
import { ExclamationCircleTwoTone, EyeTwoTone, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Moment from 'react-moment';
import { ShowIfAccess } from '../../../utils/secure';
import StatusPills from '../common/StatusPills';
import { callBackEnd } from '../../../utils/backEndUtils';

export default function NamespacesList(props: Readonly<INamespacesListProps>) {
    const { namespaces, title } = props;
    const navigate = useNavigate();

    const [search, setSearch] = useState<string>('');
    const [namespaceStaticTypes, setNamespaceStaticTypes] = useState<NamespaceType[]>([]);


    useEffect(() => {
        async function getNamespaceStaticTypes() {
            try {
                const response: any = await callBackEnd('portal', 'GET', `static/namespace_types`);
                if (Array.isArray(response)) {
                    setNamespaceStaticTypes(response);
                }
            }
            catch (error: any) {
                console.log(error);
            }
        }

        getNamespaceStaticTypes().catch((e: any) => console.log(e));
    }, []);

    const columns: TableColumnsType<Namespace> = [
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
            sorter: (a: any, b: any) => a.label.localeCompare(b.label),
            render: (label: string, record: Namespace) => {
                return record.ownerId ? label : <Tooltip title="No Namespace owner set"><ExclamationCircleTwoTone twoToneColor="#eb2f96" /> {label}</Tooltip>;
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a: any, b: any) => a.type.localeCompare(b.type),
            render: (type: NamespaceType) => {
                return <Tag color="blue">{type}</Tag>
            },
            filters: namespaceStaticTypes.map((nst: NamespaceType) => {
                return ({ text: nst, value: nst });
            }),
            onFilter: (value: any, record: any) => record.type === value,
        },
        {
            title: 'Email Domain',
            dataIndex: 'emailDomain',
            key: 'emailDomain',
            sorter: (a: any, b: any) => a.emailDomain.localeCompare(b.emailDomain),
        },
        {
            title: 'On Premise Enabled',
            dataIndex: ['options', 'onPremiseEnabled'],
            key: 'onPremiseEnabled',
            render: (onPremiseEnabled: boolean) => <StatusPills type={onPremiseEnabled} />,
            sorter: (a: any, b: any) => a.options.onPremiseEnabled - b.options.onPremiseEnabled,
            filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ],
            onFilter: (value: any, record: any) => record.options.onPremiseEnabled === value,
        },
        // {
        //     title: 'IDP Enabled',
        //     dataIndex: ['options', 'idpEnabled'],
        //     key: 'idpEnabled',
        //     render: (idpEnabled: boolean) => idpEnabled ? 'Yes' : 'No',
        //     sorter: (a: any, b: any) => a.options.idpEnabled - b.options.idpEnabled,
        //     filters: [
        //         { text: 'Yes', value: true },
        //         { text: 'No', value: false },
        //     ],
        //     onFilter: (value: any, record: any) => record.options.idpEnabled === value,
        // },
        {
            title: 'Last update',
            dataIndex: 'updated',
            key: 'updated',
            render: (v: any) => <Moment format="YYYY/MM/DD h:mm:ss a">{v}</Moment>,
            sorter: (a: any, b: any) => new Date(a.updated).getTime() - new Date(b.updated).getTime(),
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'x',
            render: (v: any, n: any) => <Tooltip title="View namespace details" placement="top"><Button type="link" onClick={() => navigate(`/namespaces/${n.uid}`)}><EyeTwoTone /></Button></Tooltip>,
        }
    ];
    return (
        <>
            {(namespaces.length > 0 && namespaceStaticTypes.length > 0) &&

                <Card
                    title={title}
                    extra={<Space size='small'>
                        <Input addonBefore={<SearchOutlined />} type="text" placeholder="Search" name="search" className="search" onChange={(e: any) => setSearch(e.target.value)} />
                        <ShowIfAccess role={['crm_namespaces']} namespaceRequired={false}>
                            <Link to="/namespaces/create">
                                <Button type="primary" icon={<PlusCircleOutlined />}>
                                    Create New Namespace
                                </Button>
                            </Link>
                        </ShowIfAccess>
                    </Space>
                    }
                >
                    <Table
                        dataSource={namespaces.filter((n: Namespace) => {
                            if (search === '') return n;
                            else if (n.name && n.name.toLowerCase().includes(search.toLowerCase())) return n;
                            else if (n.label && n.label.toLowerCase().includes(search.toLowerCase())) return n;
                            else if (n.emailDomain && n.emailDomain.toLowerCase().includes(search.toLowerCase())) return n;
                            else return null;
                        })}
                        columns={columns}
                        rowKey="uid"
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            showSizeChanger: namespaces.length > 10,
                            pageSizeOptions: ['10', '20', '30', '40', '50'],
                            defaultPageSize: 10
                        }}

                    />
                </Card>

            }
        </>
    )
}
