import { Card, Col, DescriptionsProps, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';

import Loading from './Loading';
import MotionBox from './Animated/MotionBox';
import { Outlet } from 'react-router-dom';
import { PageHeader } from './header/PageHeader';
import { ShowIfAccess } from '../../utils/secure';
import useNamespaceById from '../../hooks/useNamespaceById';

export default function NSALayout(props: any) {
    const [headerTitle, setHeaderTitle] = useState("");
    const [usersCount, setUsersCount] = useState<number>(0);
    const [packagesCount, setPackagesCount] = useState<number>(0);

    const { namespace, loading, error, getNamespaceUsersCount, getNamespacePackagesCount } = useNamespaceById();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersCount: number = await getNamespaceUsersCount();
                const packagesCount: number = await getNamespacePackagesCount();

                setUsersCount(usersCount);
                setPackagesCount(packagesCount);

            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [getNamespaceUsersCount, getNamespacePackagesCount]);


    const items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: 'Number of users',
            children: <span>{' '}{usersCount}</span>,
        },
        {
            key: '2',
            label: 'Number of packages',
            children: <span>{' '}{packagesCount}</span>,
        },
        {
            key: '3',
            label: 'Namespace email Domain',
            children: <span>{' '}@{namespace?.emailDomain}</span>,
        },
    ];


    return (
        <ShowIfAccess authenticated={true} namespaceRequired={true}>
            <div className="nsadmin">
                <MotionBox>
                    <PageHeader
                        withEnvSelector
                        title={headerTitle}
                        subtitle='Namespace Management'
                    />

                    <Row gutter={[24, 24]}>
                        <Col xs={24}>
                            {!loading && error === null ?
                                <Card>
                                    <Row gutter={[24, 24]}>
                                        <Col xs={24}><Typography.Title level={3}>Namespace details</Typography.Title></Col>
                                        {items.map((item: any) => (
                                            <Col key={item.key} xs={24} md={12} xxl={8}><span style={{ color: 'rgba(0,0,0,0.45)', marginBottom: '1rem' }}>{item.label}:</span>{item.children}</Col>
                                        ))}
                                    </Row>
                                </Card>
                                :
                                <Loading />
                            }
                        </Col>
                        <Col xs={24}>
                            <Outlet context={{ setHeaderTitle }} />
                        </Col>
                    </Row>
                </MotionBox>
            </div>
        </ShowIfAccess>
    );
}