import { Flex, Typography } from 'antd';

import { Link } from 'react-router-dom';
import { ShowIfAccess } from '../../utils/secure';
import { WidgetCard } from '../WidgetCard/WidgetCard';
import { green } from '@ant-design/colors'

export const DocWidget = (props: any) => {
    return (
        <WidgetCard style={{ backgroundColor: green[3], color: 'white', backgroundImage: "url(/welcome.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "right center" }}>
            <Flex vertical>
                <Typography.Title style={{ color: 'white', marginTop: "0" }} level={3}>Get Started with API Docs</Typography.Title>
                <ShowIfAccess authenticated={true} module='API_DOCUMENTATION'>
                    <p style={{ fontWeight: "bold" }}>
                        Dive into our <Link style={{ color: "white", textDecoration: "underline" }} to="/documentation/api-documentation">API documentation</Link> to unlock the full potential of our platform and enhance your integration experience.
                    </p>
                </ShowIfAccess>
            </Flex>
        </WidgetCard>
    );
};