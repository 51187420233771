import { Button, Card, Divider, Result, Splitter, Tag, Typography, notification } from 'antd';
import { CopyOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { SecureWithLogin, useOidcAccessToken } from '../../utils/secure';

import ReactJson from '@microlink/react-json-view';
import TryItResultsTable from '../tryIt/tables/TryItResultsTable';
import { callBackEnd } from '../../utils/backEndUtils';
import { getAvailableCollectionByProduct } from '../../utils/getCollections';
import { useState } from 'react';

export default function ScreeningBlock({ collection, engine, environment, responseDisplay }: { collection: string, engine: string, environment: string, responseDisplay: "table" | "json" }) {

    const targetEnv: string = environment.toUpperCase();
    const code: any = ({
        "options": {
            "threshold": 90
        },
        "records": [
            {
                "object": "Vladimir Putin",
                "type": "individual",
                "metadata": {
                    "dobs": [
                        "1952"
                    ],
                    "gender": "male",
                    "places": [
                        {
                            "country": "RU",
                            "city": "Moscow"
                        }
                    ]
                }
            }
        ]
    });

    const [response, setResponse] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const { accessTokenPayload } = useOidcAccessToken();

    //todo: implement the call to the backend
    // Quid: set collection ? Re-run sample ?

    const handleRunClick = () => {

        //Call the backend

        try {

            setLoading(true);
            callBackEnd(targetEnv, 'POST', `api/v1/jetscan/screen/${collection.toLowerCase()}`, JSON.stringify(code))
                .then((response: any) => {

                    setResponse(response);
                    setLoading(false);
                })
                .catch((error: any) => {
                    console.log(error)
                })
        }
        catch (e) {
            console.log(e)
        }

    }

    const handleCopyClick = (value: any) => {
        const jsonCode: string = JSON.stringify(value, null, 2);
        navigator.clipboard.writeText(jsonCode).then(() => {
            notification.success({ message: 'Request copied to clipboard' });

        }).catch(err => {
            console.error('Failed to copy URL: ', err);
        });
    }

    if (!getAvailableCollectionByProduct('JETSCAN', environment, accessTokenPayload, 1).includes(collection.toLowerCase())) {
        return (
            <Card>
                <Result status="error"
                    title={`You need to have access to the ${collection} collection on the ${environment} environment to use this feature.`}
                    subTitle="Try changing your environment using the Environment Selector on top of your screen of contact your administrator."
                />
            </Card>
        )
    }


    return (
        <SecureWithLogin role={['js_screen']} callbackPath="/" namespaceRequired={true}>
            {code !== null &&
                <Card loading={loading}
                    title="Code Sample"
                    extra={
                        <>
                            <Tag color="green">Collection: {collection}</Tag>
                            <Tag color="green">Engine: {engine}</Tag>
                            <Tag color="green">Environment: {environment}</Tag>
                        </>
                    }
                    actions={[
                        <Button type="default" onClick={() => handleCopyClick(code)}><CopyOutlined /> Copy Request to clipboard</Button>,
                        <Button type="default" disabled={!response} onClick={() => handleCopyClick(response)}><CopyOutlined /> Copy Response to clipboard</Button>,
                        <Button type="primary" onClick={handleRunClick}><PlayCircleOutlined /> Run Code</Button>
                    ]}>

                    <Splitter layout="vertical" style={{maxHeight:"1500px",minHeight:"600px"}}>
                        <Splitter.Panel min={"20%"} max={'80%'}>
                            <Splitter>
                                <Splitter.Panel defaultSize={"50%"} min={"20%"} max={'80%'}>
                                    <div style={{ padding: "20px" }}>
                                        <Typography.Title level={5}>Request</Typography.Title>
                                        <Divider />
                                        <ReactJson src={code} />
                                    </div>
                                </Splitter.Panel>
                                {response &&
                                    <Splitter.Panel defaultSize={"50%"} min={"20%"} max={'80%'}>
                                        <div style={{ padding: "20px", maxHeight: "250px" }}>
                                            <Typography.Title level={5}>Response</Typography.Title>
                                            <Divider />
                                            <ReactJson src={response} />
                                        </div>
                                    </Splitter.Panel>
                                }
                            </Splitter>
                        </Splitter.Panel>
                        {response &&
                            <Splitter.Panel min={"20%"} max={'80%'}>
                                {(response && !loading) &&
                                    <>{response?.results?.map((results: any, index: number) => {
                                        return (
                                            <div key={index}>
                                                <Divider orientation='left'>API Matches found for [{results.object}]</Divider>
                                                <Card>
                                                    <TryItResultsTable matches={results?.matches} />
                                                </Card>
                                            </div>
                                        )
                                    }
                                    )}
                                    </>
                                }
                            </Splitter.Panel>
                        }
                    </Splitter>

                </Card>
            }

        </SecureWithLogin>
    )
}
