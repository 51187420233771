import { Button, Card, Result, Space } from 'antd';
import { ShowIfAccess, useOidc } from '../../utils/secure';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { callBackEnd } from '../../utils/backEndUtils';

export default function JoinNamespace() {
    const { namespaceId } = useParams<{ namespaceId: string }>();

    const [status, setStatus] = useState<JoinRequestStatus | null>(null);
    const [checkStatus, setCheckStatus] = useState<number>(0);
    const { renewTokens, isAuthenticated } = useOidc();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        async function requestJoin() {
            try {
                setIsProcessing(true);
                const response: any = await callBackEnd('portal', 'POST', `namespaces/${namespaceId}/join`);
                setTimeout(() => {
                    setIsProcessing(false);
                }, 1000);
                setStatus(response.status);
            } catch (error: any) {
                console.log(error);
            }
        }
        isAuthenticated && requestJoin().catch((e: any) => console.log(e));
    }, [namespaceId, isAuthenticated, checkStatus])

    const refreshTokenAndRedirect = async (path: string) => {
        setIsProcessing(true);
        await renewTokens();

        setIsProcessing(false);
        navigate(path);
    }


    return (
        <ShowIfAccess authenticated={true}>
            <Card title="Join Namespace">
                {status === "PENDING" &&
                    <Result
                        status={"success"}
                        title="A Namespace already exists"
                        extra={
                            <Space direction="vertical" size="middle">
                                <p>A request has been sent to the namespace administrator for you to join.</p>
                                <p>Please wait for an administrator to validate your join request.</p>
                                <p>Status: {status}</p>
                                <Button type="primary" onClick={() => setCheckStatus(checkStatus + 1)} loading={isProcessing}>Refresh Status</Button>
                            </Space>
                        }
                    />
                }
                {status === "ACCEPTED" &&
                    <Result
                        status={"success"}
                        title="Your request has been accepted"
                        extra={
                            <Space direction="vertical" size="middle">
                            <p>Status: {status}</p>
                            <Button type="primary" onClick={() => refreshTokenAndRedirect("/")}>Enter the Neterium Portal</Button>
                            </Space>
                        }
                    />
                }
                {status === "REJECTED" &&
                    <Result
                        status={"error"}
                        title="Your request has been rejected"
                        extra={
                            <p>If you still want to proceed, please contact the Neterium team.</p>
                        }
                    />
                }
                {status === "CANCELLED" &&
                    <Result
                        status={"error"}
                        title="Your request has been cancelled"
                        extra={
                            <p>If you still want to proceed, please contact the Neterium team.</p>
                        }
                    />
                }
            </Card>
        </ShowIfAccess>
    )
}
