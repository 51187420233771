import { Button, Card, Descriptions, Space } from 'antd';
import { HomeFilled, HomeOutlined } from '@ant-design/icons';
import { SecureWithLogin, useOidcAccessToken } from "../../utils/secure";

import { Content } from 'antd/es/layout/layout';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import MotionBox from '../Layout/Animated/MotionBox';
import { PageHeader } from '../Layout/header/PageHeader';
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useRoles } from '../../contexts/RolesContext';

export default function Account() {
    const { accessTokenPayload } = useOidcAccessToken();

    const rolesCtx = useRoles();
    console.log("👉 ~ rolesCtx:", rolesCtx);
    

    return (

        <SecureWithLogin callbackPath="/account" role={[]}>
            <MotionBox>
                <PageHeader icon={<HomeFilled />} title="My Account" subtitle='Portal' breadcrumbs={[{ title: <span><HomeOutlined />&nbsp;My Account</span>, href: '/account', }]} />
                <Content>
                    <Card
                        extra={
                            <Space size={"small"}>
                                {(accessTokenPayload && accessTokenPayload?.iss) &&
                                    <a href={`${accessTokenPayload.iss}/account`} target="_blank" rel="noreferrer">
                                        <Button type="primary" icon={<FontAwesomeIcon icon={faPen}></FontAwesomeIcon>}>Edit my account</Button>
                                    </a>
                                }
                            </Space>
                        }
                    >
                        {/* {(!loading && error === null && user !== null && !isEditing) ? */}
                        {(!rolesCtx?.loading && rolesCtx !== null) ?
                            <Descriptions>
                                <Descriptions.Item label="First Name">{rolesCtx?.userInfos?.firstName}</Descriptions.Item>
                                <Descriptions.Item label="Last Name">{rolesCtx?.userInfos?.lastName}</Descriptions.Item>
                                <Descriptions.Item label="Email">{rolesCtx?.userInfos?.email}</Descriptions.Item>
                                <Descriptions.Item label="Company">{rolesCtx?.userInfos?.company}</Descriptions.Item>
                                <Descriptions.Item label="Status">{rolesCtx?.userInfos?.status}</Descriptions.Item>
                                <Descriptions.Item label="UID">{rolesCtx?.userInfos?.id}</Descriptions.Item>
                            </Descriptions>
                            :
                            <>
                                {rolesCtx?.loading && <LoadingSkeleton />}
                            </>
                        }

                    </Card>
                </Content>
            </MotionBox>
        </SecureWithLogin>
    )
}
