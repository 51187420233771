import React, { ReactNode } from "react";

type ErrorBProps = {
    children: ReactNode,
    fallback: any
};
type ErrorBState = { hasError: boolean };

class ErrorBoundary extends React.Component<ErrorBProps, ErrorBState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback;
        }

        return this.props.children;
    }
}
export default ErrorBoundary;