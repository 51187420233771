import { Button, Card, Checkbox, Col, Collapse, DatePicker, Divider, Flex, Form, Input, Radio, Row, Select, Slider, Space, Tooltip, Typography, notification } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { faCopy, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DefaultOptionType } from 'antd/es/select';
import { EnvContext } from '../../contexts/EnvironmentContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MotionBox from '../Layout/Animated/MotionBox';
import ReactJson from '@microlink/react-json-view';
import { ReadOutlined } from '@ant-design/icons';
import { SecureWithLogin } from '../../utils/secure';
import TryItDeveloperV2 from './TryItDeveloper';
import TryItResultsStats from './stats/TryItResultsStats';
import TryItResultsTable from './tables/TryItResultsTable';
import { callBackEnd } from '../../utils/backEndUtils';
import dayjs from 'dayjs';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { getCountryListAlpha2 } from '../../utils/geoInfos';

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}
const defaultRequestCode: any = {
    options: {},
    records: [],
};


const TryItForm = () => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const env: string = useContext(EnvContext);
    // const { readString } = usePapaParse();
    const [form] = Form.useForm();
    const query = useQuery(); // Get query parameters
    // setRequestCode({ options: {}, records: [{ object: query.get('name') }] }); // Reset the name field

    const resetForm = () => {
        setRequestCode(defaultRequestCode);
        setFormDisabled(false);
    };


    const [requestCode, setRequestCode] = useState<any>(defaultRequestCode);
    const [isFormSubmitable, setIsFormSubmitable] = useState<boolean>(false);
    const [responseCode, setResponseCode] = useState<any>();
    const [dobType, setDobType] = useState<string>('year');
    const [showCityField, setShowCityField] = useState<boolean>(false);
    const [showCityProximityField, setShowCityProximityField] = useState<boolean>(false);
    const [showGenderField, setShowGenderField] = useState<boolean>(false);
    const [showGenderBox, setShowGenderBox] = useState<boolean>(false);
    const [showCityBox, setShowCityBox] = useState<boolean>(false);
    const [showDobfield, setShowDobField] = useState<boolean>(false);
    const [dobBoxEnabled, setDobBoxEnabled] = useState<boolean>(false);
    const [showDobPrecisionField, setShowDobPrecisionField] = useState<boolean>(false);
    const [genderBoxEnabled, setGenderBoxEnabled] = useState<boolean>(false);
    const [countryBoxEnabled, setCountryBoxEnabled] = useState<boolean>(false);
    const [cityBoxEnabled, setCityBoxEnabled] = useState<boolean>(false);
    const [showDobBox, setShowDobBox] = useState<boolean>(false); 
    const [loading, setLoading] = useState<boolean>(false);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [formDisabled, setFormDisabled] = useState<boolean>(false);

    useEffect(() => {
        setCityBoxEnabled(!editMode);
        setGenderBoxEnabled(!editMode);
        setCountryBoxEnabled(!editMode);
        setDobBoxEnabled(!editMode);
    }, [editMode]);

    // const handleJsonChange = (e: any) => {
    //     setRequestCode(e.updated_src);
    // };

    const buildRequestCode = useCallback(async (values: any) => {
        form.validateFields();
        //Build the request code
        const rcCopy: any = { ...requestCode };
        let formattedDateOfBirth: string = '';

        /**
         * *********
         * OPTIONS
         * *********
         */
        //Options
        if(rcCopy.options){
        rcCopy.options = {};
        

        //dob policy - if dob exists nad is not checked
        if (values?.metadataDob && values?.type === "individual") {
            //add dob precision if required
            if (values?.optionDobPrecision) {
                rcCopy.options.dobPrecision = values?.optionDobPrecision;
            }else{
                rcCopy?.options?.dobPrecision && delete rcCopy?.options?.dobPrecision;
            }
            if (!values?.requiredMatches?.includes('dob')) {
                //adds dob match as optional
                rcCopy.options.dobPolicy = {
                    match: "optional",
                }
            }
        }
        else {
            rcCopy.options.dobPolicy && delete rcCopy.options.dobPolicy;
        }

        //gender policy - if gender exists and is not checked
        if (values?.metadataGender && values?.type === "individual" && !values?.requiredMatches?.includes('gender')) {
            //adds gender match as optional
            rcCopy.options.genderPolicy = {
                match: "optional"
            }
        }
        else {
            rcCopy.options.genderPolicy && delete rcCopy.options.genderPolicy;
        }

        if (values?.optionThreshold && values?.optionThreshold >= 85 && values?.optionThreshold <= 100) {
            if (values?.optionThreshold === 85) {
                delete rcCopy.options.threshold;
            }
            else {
                rcCopy.options.threshold = values?.optionThreshold;
            }
        }

        //country & city policy
        if (values?.metadataPlacesCountry) {
            if (values?.requiredMatches?.includes('country')) {
                if (values?.metadataPlacesCity) {
                    if (!values?.requiredMatches?.includes('city')) {
                        rcCopy.options.cityPolicy = { match: "optional" };
                    }
                }
            }
            else {
                rcCopy.options.countryPolicy = { match: "optional" };
                rcCopy.options.cityPolicy = { match: "optional" };
            }
            if (values?.metadataPlacesCity) {
                if (values?.optionCityProximity >= 0 && values?.optionCityProximity <= 500) {
                    if (values?.optionCityProximity === 50) { delete rcCopy.options.cityProximity; }
                    else rcCopy.options.cityProximity = values?.optionCityProximity;
                }
                else {
                    delete rcCopy.options.cityPolicy;
                }
            }
            else{
                delete rcCopy.options.cityPolicy;
                delete rcCopy.options.cityProximity;
            }
        }
        }
        /**
         * *********
         * RECORD
         * *********
         */
        rcCopy.records = [];

        rcCopy.records.push({
            ...(values?.name && values?.type && { object: values?.name?.trim() }),
        });

        if (values?.type !== 'unknown') {
            rcCopy.records[0].type = values.type;
        }

        if (rcCopy?.records[0]) {
            rcCopy.records[0].metadata = {};
        }

        //add metadataDob if exists
        if (values.metadataDob && values?.type === "individual") {
            if (rcCopy?.records[0]) {
                //format the date of birth if exists
                if (values.metadataDobType === 'year') {
                    formattedDateOfBirth = dayjs(values.metadataDob).format('YYYY');
                }
                else {
                    formattedDateOfBirth = dayjs(values.metadataDob).format('YYYY-MM-DD');
                }
                rcCopy.records[0].metadata.dobs = [formattedDateOfBirth];
            }
        }
        //add metadataGender if exists
        if (values.metadataGender && values?.type === "individual") {
            if (rcCopy?.records[0]) {
                rcCopy.records[0].metadata.gender = values.metadataGender;
            }
        }

        //add metadataPlaces if exists
        if (values.metadataPlacesCountry) {
            if (rcCopy?.records[0]) {
                rcCopy.records[0].metadata.places = [{
                    country: values.metadataPlacesCountry,
                    ...(values.metadataPlacesCity && { city: values.metadataPlacesCity })
                }];
            }
        }
        else {
            rcCopy.records[0].metadata && delete rcCopy.records[0].metadata.places;
        }

        //remove metadata if no property set
        if (rcCopy?.records[0]?.metadata) {
            if (Object.keys(rcCopy.records[0].metadata).length === 0) {
                delete rcCopy.records[0].metadata;
            }
        }
        return rcCopy;
    }, [requestCode, form]);

    const handleFormReset = () => {
        form.resetFields();
        resetForm();
        setRequestCode({ options: {}, records: [] }); // Reset the name field
        setResponseCode(undefined);
        setIsFormSubmitable(false);
        setDobType('full');
        setShowCityField(false);
        setLoading(false);
        setEditMode(false);
    }

    const handleForm = async (values: any) => {
        setLoading(true);

        const rc: any = await buildRequestCode(values);
        setRequestCode(rc);

        //Call the backend
        const targetEnv: string = (env === 'DEV') ? "DEV" : "SANDBOX";
        try {

            callBackEnd(targetEnv, 'POST', `api/v1/jetscan/screen/sanctions`, JSON.stringify(rc))
                .then((response: any) => {

                    setResponseCode(response);
                    setLoading(false);
                })
                .catch((error: any) => {
                    console.log(error)
                })
        }
        catch (e) {
            console.log(e)
        }
    }

    // console.log(requestCode)
    const handleFieldChange = (changedFields: any, allFields: any) => {
        if (changedFields[0]?.name[0] === 'name') {
            if (changedFields[0]?.value?.length) {
                setIsFormSubmitable(true);
            }
            else {
                setIsFormSubmitable(false);
            }
        }
        if (changedFields[0]?.name[0] === 'type') {

            const resetFieldsIndividual = () => {
                form.resetFields(['metadataGender', 'metadataDob', 'metadataDobType', 'optionDobPrecision']);
                setShowDobPrecisionField(false);
                setDobType('year');
            }

            if (changedFields[0]?.value === 'individual') {
                // requestCode.records[0].type = 'individual';
                setShowGenderField(true);
                setShowDobField(true);
                setShowDobBox(true);
            }
            else if (changedFields[0]?.value === 'entity') {
                // requestCode.records[0].type = 'entity';
                setShowGenderField(false);
                setShowDobField(false);
                setShowGenderBox(false);
                setShowDobBox(false);
                resetFieldsIndividual();
            }
            else {
                requestCode && delete requestCode.records[0].type;
                setShowGenderField(false);
                setShowDobField(false);
                setShowGenderBox(false);
                setShowDobBox(false);
                resetFieldsIndividual();
            }
        }

        if (changedFields[0]?.name[0] === 'metadataDob') {
            if (changedFields[0]?.value) {
                setShowDobPrecisionField(true);
                setDobBoxEnabled(true);
            }
            else {
                setShowDobPrecisionField(false);
                setDobBoxEnabled(false);
            }
        }
        if (changedFields[0]?.name[0] === 'metadataGender') {
            if (changedFields[0]?.value) {
                setShowGenderBox(true);
                setGenderBoxEnabled(true);

            }
            else {
                setGenderBoxEnabled(false);
            }
        }

        if (changedFields[0]?.name[0] === 'metadataDobType') {
            setDobType(changedFields[0]?.value || 'year');
            if (changedFields[0]?.value === 'year') {
                setShowDobBox(true);
            }
        }

        if (changedFields[0]?.name[0] === 'metadataPlacesCountry') {
            if (changedFields[0]?.value?.length) {
                setShowCityField(true);
                setCountryBoxEnabled(true);
            }
            else {
                setShowCityField(false);
                setCityBoxEnabled(false);
                setCountryBoxEnabled(false);
                setShowCityBox(false);
                setShowCityProximityField(false);
                form.setFieldsValue({ metadataPlacesCity: undefined });
                form.setFieldsValue({ optionCityProximity: undefined });
            }
        }
        if (changedFields[0]?.name[0] === 'metadataPlacesCity') {
            if (changedFields[0]?.value?.length) {
                setShowCityProximityField(true);
                setShowCityBox(true);
                setCityBoxEnabled(true);
            } else {
                setShowCityProximityField(false);
                setCityBoxEnabled(false);
                form.setFieldsValue({ optionCityProximity: undefined });
                form.setFieldsValue({ metadataPlacesCity: undefined });
                form.setFieldsValue({ requiredMatches: form.getFieldValue('requiredMatches').filter((v: string) => v !== 'city') }); //disable city checkbox
            }
        }

        if (changedFields[0]?.name[0] === 'requiredMatches') {
            if (changedFields[0]?.value.includes('dob')) {
                setShowDobBox(true);
            }
            if (changedFields[0]?.value.includes('gender')) {
                setShowGenderBox(true);
            }
            if (changedFields[0]?.value.includes('country')) {
                const cityField = allFields.find((field: any) => field.name[0] === 'metadataPlacesCity');
                if (cityField && cityField.value) {
                    setShowCityBox(true);
                    setCityBoxEnabled(true);
                }

            } else {
                // if(allFields[0]?.value.includes('city')){
                //     form.setFieldsValue({
                //         requiredMatches: allFields[0]?.value.filter((v: string) => v !== 'city')
                //     });
                // }
                if (changedFields[0]?.value.includes('city')) {
                    form.setFieldsValue({
                        requiredMatches: changedFields[0]?.value.filter((v: string) => v !== 'city')
                    });
                }
                setCityBoxEnabled(false);
            }
        }
    }

    const handleValuesChange = useCallback(async (changedValues: any, allValues: any) => {
        const request: any = await buildRequestCode(allValues);
        setRequestCode(request);
    }, [buildRequestCode]);

    const handleEditModeSwitch = () => {
        setSubmitDisabled(true);
        setEditMode(true);
        setFormDisabled(true);
    }

    const copyToClipboard = async (json: any) => {
        const formattedJson = JSON.stringify(json, null, 2); // Beautify the JSON
        navigator.clipboard.writeText(formattedJson).then(() => {
            console.log("JSON copied to clipboard!", json);
            notification.success({ message: 'JSON copied to clipboard!' });
        }).catch(err => {
            console.error("Failed to copy JSON: ", err);
        });
    };

    const handleCopyClick = (code: string) => {
        if (requestCode && code === 'request') {
            setRequestCode((prevRequestCode: any) => {
                copyToClipboard(prevRequestCode);
                return prevRequestCode;
            });
        }
        else if (responseCode && code === 'response') {
            setResponseCode((prevResponseCode: any) => {
                copyToClipboard(prevResponseCode);
                return prevResponseCode;
            });
        }
    };

    const handleCloseDevMode = () => {
        setEditMode(false);
        handleFormReset();
    }

    // Function to prefill form from query parameters
    useEffect(() => {
        if (query.size !== 0) {
            const prefillData = {
                name: query.get('object'),
                type: query.get('type') || 'unknown',
                metadataGender: query.get('metadataGender'),
                metadataDob: query.get('metadataDob'),
                metadataPlacesCountry: query.get('metadataPlacesCountry') ? query.get('metadataPlacesCountry') : undefined,
                metadataPlacesCity: query.get('metadataPlacesCity') ? query.get('metadataPlacesCity') : undefined,
                optionThreshold: Number(query.get('optionThreshold')),
                optionHolisticMatching: query.get('optionHolisticMatching'),
                optionDobPrecision: Number(query.get('optionDobPrecision')),
                optionCityProximity: Number(query.get('optionCityProximity')),
                optionDobPolicyLevel: Number(query.get('optionDobPolicyLevel')),
                // Add other fields as needed
            };

            form.setFieldsValue(prefillData);
            if (query.get('metadataPlacesCountry')) {
                setShowCityField(true);
            }
            if (query.get('type') === 'individual') {
                setShowGenderField(true);
                setShowDobField(true);
            }
            if (query.get('metadataDob')) {
                setShowDobBox(true);
            }
            if (query.get('metadataGender')) {
                setShowGenderBox(true);
            }
            // if (query.get('metadataPlacesCountry')) {
            //     setShowCountryBox(true);
            // }
            setIsFormSubmitable(true);
            handleValuesChange(prefillData, prefillData);
        }
        if (isFormSubmitable && !formDisabled && requestCode.records && requestCode.records.length > 0) {
            setSubmitDisabled(false);
        }
    }, [form, query, formDisabled, handleValuesChange, isFormSubmitable, requestCode.records]);



    return (
        <SecureWithLogin role={['js_screen']} module='TRY_IT' callbackPath="/tryIt" namespaceRequired={true}>
            <MotionBox>
                <Row gutter={[24, 24]} >
                    {/* <Divider orientation='left'>Screening Profile</Divider> */}
                    <Col xs={24} xl={12} xxl={12}>
                        <Card title="API Form" style={{ minHeight: '100%' }}>
                            <Form
                                disabled={editMode}
                                // layout='vertical'
                                // labelWrap
                                form={form}
                                onFinish={handleForm}
                                onValuesChange={handleValuesChange}
                                onFieldsChange={handleFieldChange}
                            >
                                {/* <Flex><Typography.Title level={3}>Title?</Typography.Title></Flex> */}
                                <Form.Item
                                    colon={false}
                                    label="Name"
                                    name='name'
                                    labelCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 0 }, md: { span: 6, offset: 0 }, lg: { span: 8, offset: 0 }, xxl: { span: 6, offset: 0 } }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Type the name you are screening',
                                        }
                                    ]}
                                >
                                    <Input autoComplete='off' placeholder='Full Name' type="text" value={(requestCode?.records && requestCode?.records[0]?.object) || ''} />
                                </Form.Item>

                                <Form.Item
                                    colon={false}
                                    label="Type"
                                    name='type'
                                    labelCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 0 }, md: { span: 6, offset: 0 }, lg: { span: 8, offset: 0 }, xxl: { span: 6, offset: 0 } }}
                                    initialValue={requestCode?.type ? requestCode.type.toString() : 'unknown'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a type',
                                        }
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value='unknown' name="radioUknown">Generic</Radio>
                                        <Radio value='individual' name="radioIndividual">Individual</Radio>
                                        <Radio value='entity' name="radioEntity">Entity</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {showGenderField &&
                                    <Form.Item
                                        colon={false}
                                        label="Gender"
                                        name="metadataGender"
                                        labelCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 0 }, md: { span: 6, offset: 0 }, lg: { span: 8, offset: 0 }, xxl: { span: 6, offset: 0 } }}
                                        initialValue={requestCode.metadataGender || undefined}
                                    >
                                        <Select allowClear options={[
                                            { label: "Male", value: "male" },
                                            { label: "Female", value: "female" },
                                            { label: "Other", value: "other" }
                                        ]} />
                                    </Form.Item>
                                }
                                {showDobfield &&
                                    <>
                                        <Form.Item label="Date of birth" colon={false} labelCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 0 }, md: { span: 6, offset: 0 }, lg: { span: 8, offset: 0 }, xxl: { span: 6, offset: 0 } }}>
                                            <Form.Item
                                                colon={false}
                                                name='metadataDobType'
                                                initialValue={requestCode.metadataDobType || 'year'}
                                                style={{ display: 'inline-block', marginRight: '40px' }}
                                            >
                                                <Radio.Group>
                                                    <Radio value="year">Year only</Radio>
                                                    <Radio value="full">Full date</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                            <Form.Item
                                                colon={false}
                                                name='metadataDob'
                                                initialValue={requestCode.metadataDob || null}
                                                style={{ display: 'inline-block' }}
                                            >
                                                {dobType === 'full' ?
                                                    <DatePicker maxDate={dayjs()} />
                                                    :
                                                    <DatePicker picker="year" maxDate={dayjs()} />
                                                }
                                            </Form.Item>
                                        </Form.Item>
                                        {showDobPrecisionField &&
                                            <Form.Item
                                                colon={false}
                                                label="DoB Precision"
                                                tooltip="In case of year matching, the accepted difference (in years)"
                                                name='optionDobPrecision'
                                                labelCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 0 }, md: { span: 6, offset: 0 }, lg: { span: 8, offset: 0 }, xxl: { span: 6, offset: 0 } }}
                                                initialValue={requestCode?.options?.dobPrecision || 0}
                                                hidden={!showDobfield}
                                            // wrapperCol={{ span: 12 }}
                                            >
                                                <Slider
                                                    min={0}
                                                    max={10}
                                                    marks={{
                                                        0: '0',
                                                        2: '2',
                                                        4: '4',
                                                        6: '6',
                                                        8: '8',
                                                        10: '10'
                                                    }}
                                                    value={requestCode?.options?.dobPrecision}
                                                />
                                            </Form.Item>
                                        }
                                    </>
                                }

                                <Form.Item
                                    colon={false}
                                    label="Country"
                                    name='metadataPlacesCountry'
                                    labelCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 0 }, md: { span: 6, offset: 0 }, lg: { span: 8, offset: 0 }, xxl: { span: 6, offset: 0 } }}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Select a country"
                                        optionFilterProp="label"
                                        options={getCountryListAlpha2() as DefaultOptionType[]} />
                                </Form.Item>

                                {showCityField &&
                                    <Form.Item
                                        colon={false}
                                        label="City"
                                        labelCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 0 }, md: { span: 6, offset: 0 }, lg: { span: 8, offset: 0 }, xxl: { span: 6, offset: 0 } }}
                                        tooltip="City will be ignored if a country isn't set"
                                        name='metadataPlacesCity'
                                    >
                                        <Input placeholder="Enter the city" autoComplete='off' />
                                    </Form.Item>
                                }
                                {showCityField && showCityProximityField &&
                                    <Form.Item
                                        colon={false}
                                        label="City Proximity (km)"
                                        name='optionCityProximity'
                                        labelCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 0 }, md: { span: 6, offset: 0 }, lg: { span: 8, offset: 0 }, xxl: { span: 6, offset: 0 } }}
                                        tooltip="Accepted distance (km) between two mismatched cities."
                                        initialValue={requestCode.options.cityProximity || 50}
                                    // wrapperCol={{ span: 12 }}
                                    >
                                        <Slider
                                            value={50}
                                            min={0}
                                            max={500}
                                            marks={{
                                                0: '0',
                                                100: '100',
                                                200: '200',
                                                300: '300',
                                                400: '400',
                                                500: '500',
                                            }}
                                        />
                                    </Form.Item>
                                }
                                <Divider />
                                <Form.Item
                                    colon={false}
                                    label='Required Matches'
                                    labelCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 0 }, md: { span: 6, offset: 0 }, lg: { span: 8, offset: 0 }, xxl: { span: 6, offset: 0 } }}
                                    name='requiredMatches'
                                    initialValue={['dob', 'gender', 'country', 'city']}
                                >
                                    <Checkbox.Group >
                                        {showDobBox && <Checkbox value="dob" disabled={!dobBoxEnabled}>Date of Birth</Checkbox>}
                                        {showGenderBox && <Checkbox value="gender" disabled={!genderBoxEnabled} >Gender</Checkbox>}
                                        <Checkbox value="country" disabled={!countryBoxEnabled}>Country</Checkbox>
                                        {showCityBox && <Checkbox value="city" disabled={!cityBoxEnabled}>City</Checkbox>}
                                    </Checkbox.Group>
                                </Form.Item>
                                <Divider />

                                <Form.Item
                                    colon={false}
                                    label="Threshold"
                                    name='optionThreshold'
                                    labelCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 0 }, md: { span: 6, offset: 0 }, lg: { span: 8, offset: 0 }, xxl: { span: 6, offset: 0 } }}
                                    tooltip="Name matching threshold. When the threshold value is between 50 and 84 matching is more broad, and some hits returned in this mode, are not retrieved otherwise"
                                    initialValue={requestCode?.options?.threshold}
                                // wrapperCol={{ span: 8 }}
                                >
                                    <Slider
                                        value={85}
                                        min={85}
                                        max={100}
                                        marks={{
                                            85: '85',
                                            90: '90',
                                            95: '95',
                                            100: '100'
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item name="sendRequest" wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 8, offset: 8 }, md: { span: 6, offset: 6 }, lg: { span: 8, offset: 8 }, xxl: { span: 6, offset: 6 } }}>
                                    <Space><Button type="default" name="resetButton" onClick={handleFormReset}>Reset</Button><Button type="primary" htmlType="submit" loading={loading} disabled={submitDisabled}>Execute</Button></Space>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    {/* <Divider orientation='right'>API Request</Divider> */}
                    <Col xs={24} xl={12} xxl={12}>
                        <Card title={"API Request"} style={{ minHeight: '100%' }} extra={
                            <Space size="small">
                                <Tooltip title="API Documentation" ><Link target='_blank' to="/documentation/api-documentation"><Button type="text" icon={<ReadOutlined />} /></Link></Tooltip>
                                <Typography.Text copyable={{ icon: [<Tooltip title="Copy to Clipboard" ><FontAwesomeIcon icon={faCopy} style={{ color: '#000' }} onClick={() => handleCopyClick('request')} /></Tooltip>, ''], tooltips: false }}></Typography.Text>
                                {!editMode && <Tooltip title="Edit Request"><Button type="text" icon={<FontAwesomeIcon icon={faPenToSquare} />} onClick={handleEditModeSwitch} /></Tooltip>}
                                {editMode && <Tooltip title="Cancel"><Button type="text" icon={<FontAwesomeIcon icon={faClose} />} onClick={handleCloseDevMode} /></Tooltip>}
                            </Space>
                        }>
                            {editMode ?
                                <TryItDeveloperV2 requestCode={requestCode} setRequestCode={setRequestCode} responseCode={responseCode} setResponseCode={setResponseCode} defaultRequestCode={defaultRequestCode} /> :
                                <ReactJson src={requestCode} indentWidth={2} enableClipboard={false} displayDataTypes={false} displayObjectSize={false} name={false}/>
                            }
                        </Card>
                    </Col>
                    {(responseCode && !loading) && <Col xs={24}><TryItResultsStats responseCode={responseCode} /></Col>}
                    {(responseCode && !loading) && <>{responseCode?.results?.map((results: any, index: number) => {
                        return (
                            <Col key={index} md={24} sm={24}>
                                <Divider orientation='left'>API Matches found for [{results.object}]</Divider>
                                <Card>
                                    <TryItResultsTable matches={results?.matches} />
                                </Card>
                                <Divider orientation="left">API Response</Divider>
                                <Card>
                                    <Collapse
                                        ghost
                                        style={{ flex: 1 }}
                                        items={[{
                                            key: '1',
                                            label: <Flex justify='space-between'><Typography.Text>JSON API Response</Typography.Text><Typography.Text type="secondary" copyable={{
                                                icon: [<Tooltip title="Copy to Clipboard" ><FontAwesomeIcon icon={faCopy} style={{ color: '#000' }} onClick={() => handleCopyClick('response')} /></Tooltip>, ''],
                                                tooltips: false,
                                            }}></Typography.Text></Flex>,
                                            children:
                                                <ReactJson displayDataTypes={false} enableClipboard={false} src={responseCode} indentWidth={2} displayObjectSize={false} name={false} />
                                        }]}
                                    />
                                </Card>
                            </Col>
                        )
                    })}
                    </>
                    }
                </Row>
            </MotionBox>
        </SecureWithLogin >
    )
}

export default TryItForm