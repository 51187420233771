import { Flex } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import LoadingSkeleton from "../../Layout/LoadingSkeleton";
import MotionBox from "../../Layout/Animated/MotionBox";
import { PageHeader } from "../../Layout/header/PageHeader";
import { SecureWithLogin } from '../../../utils/secure';
import UsersListWithNamespace from './UsersListWithNamespace';
import useNamespaces from '../../../hooks/useNamespaces';
import useUsers from "../../../hooks/useUsers";

export default function Users() {

    const { users, loading: usersLoading, error: usersError }: { users: UserProfile[], loading: boolean, error: any } = useUsers();

    const { namespaces, loading: loadingNamespaces, error: errorNamespaces }: { namespaces: Namespace[], loading: boolean, error: any } = useNamespaces();

    return (
        <SecureWithLogin callbackPath="/users" role={['crm_staff']} namespaceRequired={true}>
            {(!usersLoading && usersError === null && users.length > 0 && !loadingNamespaces && errorNamespaces === null && namespaces.length > 0) ?
                <MotionBox>
                    <PageHeader
                        title='All Users'
                        subtitle='Access and Enablement'
                        breadcrumbs={[
                            { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                            { title: <span>All users</span> }]}
                    />
                    <Flex vertical gap="middle">
                        <UsersListWithNamespace
                            title='Pending Users'
                            namespaces={namespaces}
                            users={users.filter((u: UserProfile) => {
                                if (u.status === 'NAMESPACE_JOIN_REQUESTED') return u
                                if (u.status === 'REGISTERED') return u
                                return null

                            })}
                        />
                        <UsersListWithNamespace
                            title='All Users'
                            namespaces={namespaces}
                            users={users}
                        />
                    </Flex>
                </MotionBox>
                :
                <LoadingSkeleton />
            }
        </SecureWithLogin>
    )
}
